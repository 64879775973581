import { CSVBoxButton } from "@csvbox/react"
import BrynsonSquareButton from "../shared/BrynsonSquareButton"
import React, { useState, useMemo } from "react"
import { FileInput } from "flowbite-react"; // Import Flowbite React FileInput


const ILSImportButtons = ({ csrfToken }) => {
  const [selectedSource, setSelectedSource] = useState("")
  const [importType, setImportType] = useState("") // 'performance' or 'lead'
  const [selectedDate, setSelectedDate] = useState("") // YYYY-MM format
  const [isImporting, setIsImporting] = useState(false)
  const [licenceKey, setlicenceKey] = useState("")
  const [selectedFile, setSelectedFile] = useState(null) // File input state
  const maxTimeframe = useMemo(() => new Date().toISOString().slice(0, 7), [])
  const minTimeframe = useMemo(
    () => new Date(new Date().setMonth(new Date().getMonth() - 11)).toISOString().slice(0, 7),
    []
  )

  const ILS_SOURCES = useMemo(
    () => [
      { value: "zillow", label: "Zillow" },
      { value: "apartments_com", label: "Apartments.com" },
      { value: "rent_com", label: "Rent.com" },
      { value: "apartments_list", label: "ApartmentsList" }
    ],
    []
  )

  const IMPORT_TYPES = useMemo(
    () => [
      { value: "performance", label: "ILS Performance" },
      { value: "lead", label: "ILS Lead" }
    ],
    []
  )

  const LICENSE_KEYS = useMemo(
    () => ({
      performance: "GmOhEKcdhuW1uEZNXk9eNvg732jLds",
      lead: "krjTu9ITT21c0KY3k5m7aAPt5D3wE9"
    }),
    []
  )

  const handleImportTypeChange = (type) => {
    setImportType(type)
    setlicenceKey(LICENSE_KEYS[type] || "")
  }

  const handleFileChange = async (event) => {
    if (!selectedSource || !importType || !selectedDate) {
      alert("Please select all required fields before importing.")
      return
    }

    const file = event.target.files[0]
    setSelectedFile(file)

    if (!file) return // Exit if no file is selected

    setIsImporting(true)

    const formData = new FormData()
    formData.append("path", file)
    formData.append("ilsSource", selectedSource)
    formData.append("timeFrame", selectedDate)
    formData.append("fileType", importType)

    try {
      const res = await fetch("/portal/ils_metrics/csv_import", {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken // CSRF token remains in the headers
        },
        body: formData // Send formData instead of JSON
      })

      if (res.ok) {
        alert("CSV data imported successfully")
      } else {
        alert("Failed to process CSV data")
      }
    } catch (error) {
      console.error("Error processing CSV data:", error)
      alert("An error occurred during the import. Please try again.")
    } finally {
      setIsImporting(false)
      setImportType("")
      setSelectedDate("")
      setSelectedSource("")
      setSelectedFile(null)
    }
  }

  const handleCSVImport = async (data) => {
    if (!selectedSource || !importType || !selectedDate) {
      alert("Please select all required fields before importing.")
      return
    }

    setIsImporting(true)
    try {
      const res = await fetch("/portal/ils_metrics/csv_import", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        body: JSON.stringify({
          data: data.rows,
          path: data.raw_file,
          ilsSource: selectedSource,
          timeFrame: selectedDate,
          fileType: importType
        })
      })

      if (res.ok) {
        alert("CSV data imported successfully")
      } else {
        alert("Failed to process CSV data")
      }
    } catch (error) {
      console.error("Error processing CSV data:", error)
      alert("An error occurred during the import. Please try again.")
    } finally {
      setIsImporting(false)
      setImportType("")
      setSelectedDate("")
      setSelectedSource("")
    }
  }

  return (
    <div className="flex items-center gap-4">
      {/* Select Source */}
      <select value={selectedSource} onChange={(e) => setSelectedSource(e.target.value)} className="rounded border p-2 w-[175px]">
        <option value="" disabled>Select ILS Source</option>
        {ILS_SOURCES.map((source) => (
          <option key={source.value} value={source.value}>
            {source.label}
          </option>
        ))}
      </select>

      {/* Import Type */}
      <select value={importType} onChange={(e) => handleImportTypeChange(e.target.value)} className="rounded border p-2 w-[175px]">
        <option value="" disabled>Select Import Type</option>
        {IMPORT_TYPES.map((type) => (
          <option key={type.value} value={type.value}>
            {type.label}
          </option>
        ))}
      </select> 

      {/* Timeframe Calendar */}
      <input type="month" min={minTimeframe} max={maxTimeframe} value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} className="rounded border p-2" />

      { (selectedSource === "apartments_com" && importType === "lead") || (selectedSource === "rent_com" && importType === "performance") ? (
        <FileInput
          id="file_input"
          name="file"
          onChange={handleFileChange}
          accept=".csv, .xlsx"
          className="w-[200px]"
          disabled={!selectedSource || !importType || !selectedDate || isImporting}
        />
      ) : (
      <CSVBoxButton
        key={`${licenceKey}-${selectedSource}-${selectedDate}-${importType}`} // This will force a re-render when the key changes
        licenseKey={licenceKey}
        user={{
          user_id: "default123",
          ilsSource: selectedSource,
          timeFrame: selectedDate,
          fileType: importType
        }}
        onImport={async (result, data) => {
          if (result) {
            await handleCSVImport(data)
          }
        }}
        render={(launch) => (
          <BrynsonSquareButton
            type="white"
            label={isImporting ? "Importing..." : "Import CSV"}
            fullWidth={false}
            onClick={launch}
            disabled={!selectedSource || !importType || !selectedDate || isImporting}
            className={`px-4 py-2 rounded ${
              !selectedSource || !importType || !selectedDate || isImporting
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-blue-600 text-white"
            }`}
          />
        )}
      >
        Import CSV
      </CSVBoxButton>
      )}
    </div>
  )
}

export default ILSImportButtons
