export const PROPERTY_DIRECTORY_QUERY = `
  query PropertyDirectory($id:ID!){
    propertyDirectory(id:$id) {
      id
      month
      year
      current
      costarTotal
      zillowTotal
      rentComTotal
      apartmentListTotal
      zumperTotal
      rentableTotal
      realtorTotal
      apartmentListPplFee
      zillowPplFee
      createdAt
      propertyDirectoryInfo {
        id
        name
        unitCount
        city
        address
        zip
        region
        pmsUsed
        crmUsed
        propertyId
        propertyDirectoryId

        state {
          abbreviation
        }
        currentMonthOccupancyPercentage
        askingRentPerSf
        currentCostarRate
        currentZillowRate
        currentRentComRate
        currentApartmentListRate
        currentZumperRate
        currentRentableRate
        brynsonIlsManagementFee
        brynsonCostarRate
        costarPropertyTaxes
        brynsonZillowRate
        zillowPropertyTaxes
        rentPropertyTaxes
        apartmentListPropertyTaxes
        zumperPropertyTaxes
        rentablePropertyTaxes
        realtorPropertyTaxes
        currentRentSocialPackage
        brynsonRentSocialRate
        rentSocialPropertyTaxes
        apartmentListPplFee
        zillowPplFee
        apartmentListPlatformFee
        brynsonRentRate
        brynsonApartmentListRate
        brynsonZumperRate
        brynsonRentableRate
        brynsonRealtorRate
        brynson3dApartmentsRate
        current3dApartmentsRate
        currentRealtorRate
        currentCostarPackage
        currentZillowPackage
        currentRentComPackage
        apartmentListPackage
        currentZumperPackage
        currentRentablePackage
        current3dApartmentsPackage
        currentRealtorPackage
        totalIlsSpend
        ownershipGroup
        region
        apartmentListLeases
        apartmentListPplTotal
        zillowLeases
        zillowPplTotal
        costarChanges
        zillowChanges
        rentComChanges
        apartmentListChanges
        zumperChanges
        rentableChanges
        realtorChanges
        otherZumperProduct
        otherZumperProductBrynsonRate
        otherCostarProduct
        otherCostarProductBrynsonRate
        otherZillowProduct
        otherZillowProductBrynsonRate
        otherApartmentListProduct
        otherApartmentListProductBrynsonRate
        otherRentComProduct
        otherRentComProductBrynsonRate
        brynsightsTotal
      }
    }
  }
`
export const PROPERTIES_DIRECTORY_QUERY = `
  query {
    propertyDirectories {
      id
      month
      year
      current
      costarTotal
      zillowTotal
      rentComTotal
      apartmentListTotal
      zumperTotal
      rentableTotal
      realtorTotal
      rentSocialTotal
      maxLeasesTotal
      apartmentListPplFee
      zillowPplFee
      createdAt
      propertyDirectoryInfo {
        id
        name
        unitCount
        city
        address
        zip
        pmsUsed
        crmUsed
        propertyId
        propertyDirectoryId
        state {
          abbreviation
        }
        currentMonthOccupancyPercentage
        askingRentPerSf
        currentCostarRate
        currentZillowRate
        currentRentComRate
        currentApartmentListRate
        currentZumperRate
        currentRentableRate
        brynsonIlsManagementFee
        brynsonCostarRate
        costarPropertyTaxes
        brynsonZillowRate
        zillowPropertyTaxes
        rentPropertyTaxes
        apartmentListPropertyTaxes
        zumperPropertyTaxes
        rentablePropertyTaxes
        realtorPropertyTaxes
        currentRentSocialPackage
        brynsonRentSocialRate
        rentSocialPropertyTaxes
        apartmentListPplFee
        zillowPplFee
        apartmentListPlatformFee
        brynsonRentRate
        brynsonApartmentListRate
        brynsonZumperRate
        brynsonRentableRate
        brynsonRealtorRate
        brynson3dApartmentsRate
        current3dApartmentsRate
        currentRealtorRate
        currentCostarPackage
        currentZillowPackage
        currentRentComPackage
        apartmentListPackage
        currentZumperPackage
        currentRentablePackage
        current3dApartmentsPackage
        currentRealtorPackage
        totalIlsSpend
        ownershipGroup
        region
        apartmentListLeases
        apartmentListPplTotal
        zillowLeases
        zillowPplTotal
        costarChanges
        zillowChanges
        rentComChanges
        apartmentListChanges
        zumperChanges
        rentableChanges
        realtorChanges
        otherZumperProduct
        otherZumperProductBrynsonRate
        brynsightsTotal
      }
    }
  }
`

export const CREATE_PROPERTY_DIRECTORY_MUTATION = `
  mutation CreatePropertyDirectoryMutation($month:String, $current:Boolean, $year:String, $companyId:ID!, $costarTotal:Float, $zillowTotal:Float, $rentComTotal:Float, $apartmentListTotal:Float, $zumperTotal:Float, $rentableTotal:Float, $realtorTotal:Float, $rentSocialTotal:Float, $maxLeasesTotal:Float, $apartmentListPplFee:Float, $zillowPplFee:Float, $propertyDirectoryInfoIds:[ID!], $propertyIds:[ID!]){
    createPropertyDirectoryMutation(month:$month,current:$current,year:$year,companyId:$companyId,costarTotal:$costarTotal,zillowTotal:$zillowTotal,rentComTotal:$rentComTotal,apartmentListTotal:$apartmentListTotal,zumperTotal:$zumperTotal,rentableTotal:$rentableTotal,rentSocialTotal:$rentSocialTotal,maxLeasesTotal:$maxLeasesTotal, apartmentListPplFee: $apartmentListPplFee, zillowPplFee: $zillowPplFee, propertyDirectoryInfoIds:$propertyDirectoryInfoIds, propertyIds:$propertyIds){
      id
      month
      current
      year
      companyId
      costarTotal
      zillowTotal
      rentComTotal
      apartmentListTotal
      zumperTotal
      rentableTotal
      realtorTotal
      rentSocialTotal
      apartmentListPplFee
      zillowPplFee
      propertyDirectoryInfo {
        id
      }
    }
  }
`

export const UPDATE_PROPERTY_DIRECTORY_MUTATION = `
  mutation UpdatePropertyDirectoryMutation($month:String, $current:Boolean, $year:String, $id:ID!, $costarTotal:Float, $zillowTotal:Float, $rentComTotal:Float, $apartmentListTotal:Float, $zumperTotal:Float, $rentableTotal:Float, $realtorTotal:Float, $rentSocialTotal:Float, $maxLeasesTotal:Float, $apartmentListPplFee:Float, $zillowPplFee:Float){
    updatePropertyDirectoryMutation(month:$month,current:$current,year:$year,id:$id,costarTotal:$costarTotal,zillowTotal:$zillowTotal,rentComTotal:$rentComTotal,apartmentListTotal:$apartmentListTotal,zumperTotal:$zumperTotal,rentableTotal:$rentableTotal,realtorTotal:$realtorTotal, rentSocialTotal:$rentSocialTotal, maxLeasesTotal:$maxLeasesTotal, apartmentListPplFee: $apartmentListPplFee, zillowPplFee: $zillowPplFee){
      id
      month
      current
      year
      companyId
      costarTotal
      zillowTotal
      rentComTotal
      apartmentListTotal
      zumperTotal
      rentableTotal
      realtorTotal
      rentSocialTotal
      maxLeasesTotal
      apartmentListPplFee
      zillowPplFee
      properties {
        id
        name
      }
    }
  }
`
