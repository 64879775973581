import React from 'react';
import { marked } from 'marked';
import PaulSmartDataViewer from './PaulSmartDataViewer';
import DOMPurify from 'dompurify';
import PaulReportViewer from './PaulReportViewer';
import {
  renderBarChart,
  renderLineChart,
  renderPieChart,
  renderHorizontalBarChart,
  renderStackedBarChart,
  formatValue
} from './PaulChartRenderers';

const MessageTypes = {
  TEXT: 'text',
  TABLE: 'table',
  THINKING: 'thinking',
  ERROR: 'error',
  VISUALIZATION: 'visualization',
  REPORT: 'report'
};

const Senders = {
  BOT: 'bot',
  USER: 'user'
};

const PaulFormattedMessage = ({ message, userAvatar, onSaveChart, showAvatar }) => {

  const createMarkup = (content) => {
    let formattedContent = content;
    if (typeof content === 'string' && content.includes('Context:')) {
      formattedContent = content.split('\n\nContext:')[0];
    }

    const rawMarkup = marked(formattedContent || '');
    const cleanMarkup = DOMPurify.sanitize(rawMarkup);
    return { __html: cleanMarkup };
  };

  const renderVisualization = (chartType, data, config, columns) => {

    switch (chartType) {
      case 'bar':
        return renderBarChart(data, config, columns);
      case 'line':
        return renderLineChart(data, config, columns);
      case 'pie':
        return renderPieChart(data, config, columns);
      case 'horizontal_bar':
        return renderHorizontalBarChart(data, config, columns);
      case 'stacked_bar':
        return renderStackedBarChart(data, config, columns);
      default:
        return renderBarChart(data, config, columns);
    }
  };

  const handleSave = (data) => {
    onSaveChart({
      ...data,
      original_data: message.df,
      team_sql: message.team_sql,
      column_formats: message.column_formats,
      metadata: {
        question: message.question,
        timestamp: new Date().toISOString(),
        context_type: message.type
      }
    });
  };

  const isBot = message.sender === Senders.BOT;
  const isTable = message.type === MessageTypes.TABLE;
  const isThinking = message.type === MessageTypes.THINKING;
  const isError = message.type === MessageTypes.ERROR;
  const isVisualization = message.type === MessageTypes.VISUALIZATION;
  const isReport = message.type === MessageTypes.REPORT;

  const shouldHaveFullStyling = isError || isThinking || (message.text && message.text.length > 50);

  return (
    <div className="flex flex-row items-start mb-4">
      {isBot && !isTable && !isVisualization && !isReport && showAvatar && (
        <div className="rounded-full bg-bgold h-[50px] w-[50px] flex items-center justify-center shrink-0">
          <span className="text-white font-semibold text-lg">Paul</span>
        </div>
      )}

      <div className={`ml-3 text-lg rounded ${
        !isBot ? 'bg-blight ml-auto px-4' :
        isVisualization || isReport ? 'bg-white border border-gray-200 w-full' :
        shouldHaveFullStyling ? 'bg-white border border-gray-200' : ''
      } ${!isTable && !isVisualization && !isReport ? 'max-w-[90%]' : 'w-full'} ${
        isThinking ? 'bg-gray-50 p-4' :
        shouldHaveFullStyling || isVisualization || isReport ? 'p-4' : 'py-1'
      } ${isError ? 'bg-red-50 border-red-200 p-4' : ''}`}>
        {isReport ? (
          <PaulReportViewer
            data={message.reportData}
            title={message.reportTitle}
            sections={message.dataSections}
            columns={message.columns}
            sql={message.sql}
            onSaveReport={handleSave}
            originalContext={{
              team_sql: message.team_sql,
              column_formats: message.column_formats,
              query: message.question
            }}
          />
        ) : isVisualization ? (
          <div className="space-y-4">
            {message.config.title && (
              <h3 className="text-lg font-medium text-gray-900">
                {message.config.title}
              </h3>
            )}
            {renderVisualization(
              message.chart_type,
              message.data,
              message.config,
              message.columns
            )}
            <div className="flex justify-end">
              <button
                onClick={() => handleSave({
                  sql: message.sql,
                  chart_type: message.chart_type,
                  data: message.data,
                  columns: message.columns,
                  config: message.config
                })}
                className="text-blue-600 hover:text-blue-700 text-sm"
              >
                Save Chart
              </button>
            </div>
          </div>
        ) : isTable ? (
          <div className="space-y-4">
            <PaulSmartDataViewer
              df={message.text}
              sql={message.sql}
              columns={message.columns}
              onSaveChart={handleSave}
              originalContext={{
                team_sql: message.team_sql,
                column_formats: message.column_formats,
                query: message.question
              }}
            />
          </div>
        ) : (
          <div className={`flex ${isThinking ? 'items-center' : 'items-start'}`}>
            {isThinking && (
              <div className="mr-2 animate-spin h-4 w-4 border-2 border-bgold rounded-full border-t-transparent"/>
            )}
            <div
              dangerouslySetInnerHTML={createMarkup(message.text)}
              className="prose prose-lg prose-slate max-w-none [&>*:first-child]:mt-0 [&>*:last-child]:mb-0"
            />
          </div>
        )}
      </div>

      {!isBot && showAvatar && (
        <img
          src={userAvatar}
          alt="Avatar"
          className="h-[50px] w-[50px] rounded-full object-cover ml-3 shrink-0"
        />
      )}
    </div>
  );
};

export default PaulFormattedMessage;