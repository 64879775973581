import { Viewer, Worker } from "@react-pdf-viewer/core"
import { Button } from "flowbite-react"
import React, { useState, useEffect, lazy, Suspense } from "react"
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useQuery } from "urql"

import { COMP_ANALYSES_QUERY } from "../../queries/CompAnalysesMutations"
import { PROPERTIES_QUERY } from "../../queries/PropertiesMutations"
import Selectbox from "../../shared/Selectbox"

// Lazy load the CompAnalysisReports component
const CompAnalysisReports = lazy(() => import('./CompAnalysisReports'));

const LoadingSkeleton = () => (
  <div className="p-8">
    {/* Description Text Skeleton */}
    <div className="space-y-4 mb-8">
      <div className="h-6 bg-gray-200 w-3/4 rounded animate-pulse" />
      <div className="h-6 bg-gray-200 w-2/3 rounded animate-pulse" />
    </div>

    {/* Selector Skeletons */}
    <div className="flex flex-row gap-4">
      <div className="w-[300px]">
        <div className="h-5 w-20 bg-gray-200 rounded animate-pulse mb-2" /> {/* Label */}
        <div className="h-10 w-full bg-gray-200 rounded animate-pulse" /> {/* Select box */}
      </div>
      <div className="w-[300px]">
        <div className="h-5 w-20 bg-gray-200 rounded animate-pulse mb-2" /> {/* Label */}
        <div className="h-10 w-full bg-gray-200 rounded animate-pulse" /> {/* Select box */}
      </div>
    </div>

    {/* PDF Viewer Skeleton */}
    <div className="mt-8 flex justify-center">
      <div className="w-2/3 h-[600px] bg-gray-200 rounded animate-pulse" />
    </div>
  </div>
);

export default function DisplayComponent({ compAnalysisType, rand }) {
  const [currentPropertyId, setCurrentPropertyId] = useState(null)
  const [currentCompAnalysisId, setCurrentCompAnalysisId] = useState(null)

  const [{ data: propertiesData, fetching: propertiesFetching }] = useQuery({
    query: PROPERTIES_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: compAnalysesData, fetching: compAnalysesFetching }] = useQuery({
    query: COMP_ANALYSES_QUERY,
    suspense: true,
    requestPolicy: "cache-first"
  })

  useEffect(() => {
    if (propertiesData?.properties?.length > 0 && !currentPropertyId) {
      const firstProperty = propertiesData.properties[0];
      setCurrentPropertyId(firstProperty.id.toString());
    }
  }, [propertiesData]);

  useEffect(() => {
    const analyses = filteredCompAnalyses();
    if (analyses?.length > 0 && !currentCompAnalysisId) {
      setCurrentCompAnalysisId(analyses[0].id);
    }
  }, [currentPropertyId, compAnalysesData]);

  // Pre-fetch PDF worker
  useEffect(() => {
    const workerUrl = "https://unpkg.com/pdfjs-dist@2.7.570/es5/build/pdf.worker.js";
    const preloadLink = document.createElement('link');
    preloadLink.rel = 'preload';
    preloadLink.as = 'script';
    preloadLink.href = workerUrl;
    document.head.appendChild(preloadLink);

    return () => {
      document.head.removeChild(preloadLink);
    };
  }, []);

  const filteredCompAnalyses = () => {
    if (!compAnalysesData) return []
    const d = compAnalysesData?.compAnalyses.filter((compAnalysis) => compAnalysis.type === compAnalysisType)
    return d.filter((compAnalysis) => compAnalysis.propertyId === parseInt(currentPropertyId))
  }

  const selectedCompAnalysis = () =>
    filteredCompAnalyses()?.find((compAnalysis) => compAnalysis.id === currentCompAnalysisId)

  const selectableCompAnalyses = () =>
    filteredCompAnalyses()?.map((compAnalysis) => ({
      value: compAnalysis.id,
      label: `${compAnalysis.month}/${compAnalysis.year}`
    }))

  const createPropertySelector = () => {
    if (propertiesData) {
      const selectedProperty = propertiesData.properties.find(p => p.id.toString() === currentPropertyId);
      return (
        <Selectbox
          key={compAnalysisType + rand}
          label="Select Property"
          random={rand}
          defaultValue={selectedProperty && {
            value: selectedProperty.id,
            label: selectedProperty.name
          }}
          onChange={(value) => {
            setCurrentPropertyId(value)
          }}
          options={
            propertiesData &&
            propertiesData.properties.map((property) => ({
              value: property.id,
              label: property.name
            }))
          }
          className="mt-4"
        />
      )
    }
  }

  const createCompAnalysisSelector = () => {
    const analyses = filteredCompAnalyses();
    if (analyses?.length) {
      const selectedAnalysis = analyses.find(a => a.id === currentCompAnalysisId);
      return (
        <Selectbox
          key={compAnalysisType + rand}
          random={rand}
          label="Select Month/Year"
          defaultValue={selectedAnalysis && {
            value: selectedAnalysis.id,
            label: `${selectedAnalysis.month}/${selectedAnalysis.year}`
          }}
          options={selectableCompAnalyses()}
          onChange={(value) => {
            setCurrentCompAnalysisId(value)
          }}
        />
      )
    }
  }

  // Early return for loading state
  if (propertiesFetching || compAnalysesFetching) {
    return <LoadingSkeleton />;
  }

  // Early return for errors
  if (!propertiesData || !compAnalysesData) {
    return (
      <div className="p-8 text-red-500">
        Error loading data. Please try refreshing the page.
      </div>
    );
  }

  return (
    <>
      {compAnalysisType !== "other" ? (
        <div>
          <div className="flex flex-col justify-center">
            <p className="mb-2 mt-8 w-[1000px] text-lg font-medium ">
              CoStar Rent Comparables Reports are generated for each of your properties on a quarterly basis and
              uploaded here.
            </p>
            <p className="mb-4 mt-2 w-[1000px] text-lg font-medium">
              Please select a property and then a month below to view.
            </p>
          </div>
          <div className="flex flex-row gap-4">
            <div className="mt-4 block w-[300px]">
              <label>Property:</label>
              {createPropertySelector()}
            </div>
            <div className="mt-4 block w-[300px]">
              <label>Month/Year:</label>
              {createCompAnalysisSelector()}
            </div>
          </div>
          <div>
            {filteredCompAnalyses && currentCompAnalysisId && (
              <div className="mt-8 flex w-full justify-center">
                <div className="w-2/3">
                  {selectedCompAnalysis() && (
                    <div>
                      <div className="text-right">
                        <a
                          href={selectedCompAnalysis()["attachment"]}
                          download
                          target="_blank"
                          rel="noopener noreferrer">
                          <Button className="mb-4 inline-flex justify-end">
                            <ArrowDownTrayIcon width={25} />
                          </Button>
                        </a>
                      </div>
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/es5/build/pdf.worker.js">
                        <Viewer fileUrl={selectedCompAnalysis()["attachment"]} />
                      </Worker>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Suspense fallback={<LoadingSkeleton />}>
          <div className="flex flex-col justify-center">
            <p className="mb-2 mt-8 w-[1000px] text-lg font-medium">
              Data for your custom competitive analysis reports are sourced with data from{" "}
              <a href="https://www.hellodata.ai/" className="text-bgold underline" target="new">
                HelloData
              </a>
              . HelloData crawls 30M+ units and 3.5M properties per day from every CRE source.
            </p>
            <p className="mb-4 mt-2 w-[1000px] text-lg font-medium">
              In Brynsights, we create a report for each of your properties and four competitors. You can edit the
              report to change the comp set.
            </p>
          </div>
          <CompAnalysisReports />
        </Suspense>
      )}
    </>
  )
}