import { ExportToCsv } from 'export-to-csv';
import { formatValue } from './PaulChartRenderers';

export const exportCSV = (data, filenamePrefix, columns = []) => {
  if (!data || data.length === 0) {
    console.error("No data available to export.");
    return;
  }

  // Format the data using our column definitions
  const formattedData = data.map(row => {
    const formattedRow = {};
    Object.entries(row).forEach(([key, value]) => {
      const column = columns.find(col => col.field === key);
      formattedRow[column?.headerName || key] = column ?
        formatValue(value, column.type) :
        value;
    });
    return formattedRow;
  });

  // Generate filename from title and date
  const date = new Date().toISOString().split('T')[0];
  const filename = `${filenamePrefix} ${date}`;

  const csvExporter = new ExportToCsv({
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    filename: filename,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  });

  csvExporter.generateCsv(formattedData);
};

// Clean up display names for common terms
export const cleanDisplayName = (name) => {
  return name
    .replace(/\bCostar\b/g, 'CoStar')
    .replace(/\bAptlist\b/g, 'Apartment List')
    .replace(/\bIls\b/gi, 'ILS');
};

// Helper to generate header name from field
export const generateHeaderName = (field) => {
  return cleanDisplayName(
    field.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  );
};