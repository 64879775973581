import React, { useState, useContext } from "react"
import { useMutation } from "urql";
import BrynsonSquareButton from "../../../shared/BrynsonSquareButton"
import Modal from "../../../shared/Modal"
import DesktopH5 from "../../../shared/DesktopH5"
import InputField from "../../../shared/InputField"
import SelectField from "../../../shared/SelectField"
import CheckBoxExtended from "../../../shared/CheckboxExtended"
import BrynsonDatePicker from "../../../shared/BrynsonDatePicker"
import { GlobalContext } from "../../../context/GlobalContext"
import { CREATE_CLIENT_ACCESS_MUTATION, UPDATE_CLIENT_ACCESS_MUTATION } from "../../../queries/ClientAccessesMutations";

export default function ClientAccessForm({ item = {}, OpenModalComponent = null}) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentInput, setCurrentInput] = useState(
    {...{fullname: '', dateAdded: '', title: '', email: '', region: '', access: [], propertiesToAccess: [], ...item}
  })

  const accessOptions = ['Full Admin', 'ILS Management', 'Competitor Analysis', "Who's Searching What", 'Portfolio Intelligence (BI)', '3rd Party Integrations', 'Paul'].map(x => ({value: x, label: x}))
  const title = item?.id ? 'Edit ClientAccess' : 'Add Client Access'
  const { propertiesData: propertiesData } = useContext(GlobalContext)
  const propertyOptions = propertiesData?.properties.map( x => ({ value: x.id, label: x.name })) || []

  const DefaultOpenModalComponent = ({ onClick, label }) => (
    <a className="font-medium text-bblue underline" onClick={onClick}>{label}</a>
  );
  const OpenComponent = OpenModalComponent || DefaultOpenModalComponent;

  const addValue = (field, value) => {
    const current = {}
    current[field] = value
    
    setCurrentInput({ ...currentInput, ...current })
  }

  const [, createClientAccess] = useMutation(CREATE_CLIENT_ACCESS_MUTATION)
  const [, updateClientAccess] = useMutation(UPDATE_CLIENT_ACCESS_MUTATION)
  const save = () => {
    setLoading(true)
    const payload = currentInput

    if(payload.id) {
      updateClientAccess(payload).then((result) => {
        if (result?.error || result?.data?.contact?.errors) {
          setError(`Error updating contact: ${result?.error || result?.data?.contact?.errors}`)
        } else {
          setOpen(false)
        }
        setLoading(false)
      })
    } else {
      createClientAccess(payload).then((result) => {
        if (result?.error || result?.data?.contact?.errors) {
          setError(`Error creating contact: ${result?.error || result?.data?.contact?.errors}`)
        } else {
          setOpen(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <>
      <OpenComponent onClick={() => setOpen(true)} label={item?.name} />
      <Modal open={open} key='property-info-form-model' closeModal={() => setOpen(false)}
        header={
          <>
            <DesktopH5 content={title} />
          </>
        }
        footer={
          <>
            <BrynsonSquareButton
              type="tertiary"
              onClick={() => setOpen(false)}
              label="Cancel"
            />
            <BrynsonSquareButton
              type="primary"
              testId="add-new-property-button"
              loading={loading}
              onClick={save}
              label="Save"
            />
          </>
        }>
        <div className="my-4 mx-2">
          {error && <p className="text-red-600 mb-2">{error}</p>}
          <div className="mb-2 w-full">
            <div className="flex">
              <CheckBoxExtended 
                id={'client-access-complete'}
                checked={item.complete}
                handleClick={(value) => {
                  addValue("complete", value)
                }}
              />
              <label htmlFor="client-access-complete" className="ml-2">Complete</label>
            </div>
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="client-access-fullname">Full Name</label>
            <div className="mb-2 w-full">
              <InputField
                id={'client-access-fullname'}
                placeholder='Full Name'
                defaultValue={item.fullname}
                onChange={(e) => addValue("fullname", e.target.value)}
              />
            </div>
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="client-access-date-added">Date Added</label>
            <BrynsonDatePicker
              id={'client-access-date-added'}
              placeholder='Date Added'
              selectedDate={
                item.dateAdded
                ? new Date(`${item.dateAdded}T00:00:00`)
                : null
              }
              onChange={(date) => addValue("dateAdded", date)}
            />
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="client-access-title">Title</label>
            <div className="mb-2 w-full">
              <InputField
                id={'client-access-title'}
                placeholder='Title'
                defaultValue={item.title}
                onChange={(e) => addValue("title", e.target.value)}
              />
            </div>
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="client-access-title">E-mail</label>
            <div className="mb-2 w-full">
              <InputField
                id={'client-access-email'}
                placeholder='E-mail'
                type={'email'}
                defaultValue={item.email}
                onChange={(e) => addValue("email", e.target.value)}
              />
            </div>
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="client-access-title">Region</label>
            <div className="mb-2 w-full">
              <InputField
                id={'client-access-region'}
                placeholder='Region'
                defaultValue={item.region}
                onChange={(e) => addValue("region", e.target.value)}
              />
            </div>
          </div>

          <div className="mb-2 w-full">
            <label htmlFor="client-access-access">Access</label>
            <div className="mb-2 w-full">
              <SelectField
                options={accessOptions}
                isMulti={true}
                id={'client-access-access'}
                defaultValue={ item.access ? accessOptions.filter(x => item.access.includes(x.value)) : [] }
                onChange={(value) => addValue("access", value)}
              />
            </div>
          </div>

          <div className="mb-2 w-full">
            <label htmlFor="client-access-properties-to-access">Properties To Access</label>
            <div className="mb-2 w-full">
              <SelectField
                options={propertyOptions}
                isMulti={true}
                id={'client-access-properties-to-access'}
                defaultValue={ item.propertiesToAccess ? propertyOptions.filter(x => item.propertiesToAccess.includes(x.value)) : [] }
                onChange={(value) => addValue("propertiesToAccess", value)}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}