export const CLIENT_ACCESSES_QUERY = `
  query ClientAccesses{
    clientAccesses {
      id
      companyId
      fullname
      dateAdded
      title
      email
      region
      access
      complete
      propertiesToAccess
    }
  }
`

export const CLIENT_ACCESS_QUERY = `
  query ClientAccess($id:ID!){
    clientAccess(id:$id) {
      id
      companyId
      fullname
      dateAdded
      title
      email
      region
      access
      complete
      propertiesToAccess
    }
  }
`

export const CREATE_CLIENT_ACCESS_MUTATION = `
  mutation CreateClientAccessMutation($fullname: String!, $dateAdded: String, $title: String, $email: String, $region: String, $access: JSON, $propertiesToAccess: JSON, $complete:Boolean) {
    createClientAccessMutation(
      fullname: $fullname
      dateAdded: $dateAdded
      title: $title
      email: $email
      region: $region
      access: $access
      propertiesToAccess: $propertiesToAccess
      complete: $complete
    ) {
      id
      companyId
      fullname
      dateAdded
      title
      email
      region
      access
      complete
      propertiesToAccess
    }
  }
`

export const UPDATE_CLIENT_ACCESS_MUTATION = `
  mutation UpdateClientAccessMutation($id: ID!, $fullname: String!, $dateAdded: String, $title: String, $email: String, $region: String, $access: JSON, $propertiesToAccess: JSON, $complete:Boolean) {
    updateClientAccessMutation(
      id: $id
      fullname: $fullname
      dateAdded: $dateAdded
      title: $title
      email: $email
      region: $region
      access: $access
      propertiesToAccess: $propertiesToAccess
      complete: $complete
    ) {
      id
      companyId
      fullname
      dateAdded
      title
      email
      region
      access
      complete
      propertiesToAccess
    }
  }
`

export const DELETE_CLIENT_ACCESS_MUTATION = `
  mutation DeleteClientAccessMutation($id: ID!) {
    deleteClientAccessMutation(id: $id) {
      result
      errors
    }
  }
`
