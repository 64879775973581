import React, { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { PencilIcon } from '@heroicons/react/24/outline';
import { client as filestack } from "filestack-react"

export default function ProfilePhoto({filestackApiKey}) {
  const { currentUserData, updateCurrentUser, currentUserReload } = useContext(GlobalContext)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [filestackPhoto, setFilestackPhoto] = useState('');
  const acceptfileTypes = ["image/jpeg", "image/png"]

  const picker = filestack.Filestack(filestackApiKey, {}).picker({
    displayMode: 'overlay',
    container: "#asset-container",
    maxFiles: 1,
    accept: acceptfileTypes,
    storeTo: {
      location: "s3",
      access: "public"
    },
    onUploadDone: (result) => {
      setFilestackPhoto(JSON.stringify(result.filesUploaded[0]))
    }
  })

  const updatePhoto = async () => {
    setLoading(true)
    await updateCurrentUser({filestackPhoto: filestackPhoto})
    await currentUserReload()
    window.location.reload()
    setLoading(false)
  }

  useEffect(() => {
    if (currentUserData) {
      setData(currentUserData.currentUser)
    }

  }, [currentUserData])

  useEffect(() => {
    if (filestackPhoto) {
      updatePhoto()
    }

  }, [filestackPhoto])

  return (
    <div>
      { data && <div className="flex items-center">
        <div className="relative">
          <img src={data.photoUrl} alt="Avatar" className="w-32 h-32 rounded-full object-cover" />
          <button
            disabled={loading}
            className="absolute bottom-0 right-0 bg-blue-500 text-white p-2 rounded-full shadow-lg hover:bg-blue-600"
            onClick={() => {
              setLoading(true)
              picker.open().then(() => {
                setLoading(false)
              })
            }}
          >
            {loading ? <p>Loading...</p> : <PencilIcon width={15}/>}
          </button>
        </div>
      </div>
    }
    </div>
  )
}