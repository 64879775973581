import React from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { exportCSV } from './PaulChartUtils';
import AGGridTable from '../shared/AGGridTable';

const PaulSmartDataViewer = ({ df, sql, columns = [], onSaveChart }) => {
  const data = React.useMemo(() => JSON.parse(df), [df]);
  const headerColumns = columns.map(col => ({
    title: col.headerName || col.field.split('_').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' '),
    accessor: col.field,
    type: col.type,
    minWidth: 150
  }));

  const calculateHeight = () => {
    const rowHeight = 48;
    const baseHeight = 150;
    const height = baseHeight + (data.length * rowHeight);
    return Math.max(350, Math.min(height, 800));
  };

  const gridHeight = `${calculateHeight()}px`;

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
      <div className="p-4 border-b flex justify-between items-center">
        <div className="text-sm font-medium text-gray-700">
          Data Table View
        </div>
        <div className="flex items-center gap-3">
          <button
            onClick={() => onSaveChart({
              sql,
              chart_type: 'table',
              data: data,
              columns: columns,
              config: {
                title: headerColumns[0]?.title || 'Data Table'
              }
            })}
            className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-700 text-sm"
            title="Save this view for automatic updates"
          >
            Save View
          </button>
          <button
            onClick={() => exportCSV(data, 'Brynsights Export')}
            className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-700 text-sm"
          >
            <ArrowDownTrayIcon className="h-4 w-4" />
            Export
          </button>
        </div>
      </div>
      <div style={{ height: gridHeight, width: '100%' }}>
        <AGGridTable
          data={data}
          headerColumns={headerColumns}
          height={gridHeight}
        />
      </div>
    </div>
  );
};

export default PaulSmartDataViewer;