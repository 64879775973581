import React, { useContext, useEffect, useState, useRef } from "react"
import moment from "moment-timezone"
import BreadCrumbs from "../../../shared/BreadCrumbs"
import DesktopH5 from "../../../shared/DesktopH5"
import InputField from "../../../shared/InputField"
import BrynsonSquareButton from "../../../shared/BrynsonSquareButton"
import { GlobalContext } from "../../../context/GlobalContext"
import ContactForm from "./ContactForm"
import ClientAccessForm from "./ClientAccessForm"
import ContactGrid from "./ContactGrid"
import ClientAccessGrid from "./ClientAccessGrid"
import OnboardingGrid from "./OnboardingGrid"
import { AgGridReact } from "ag-grid-react";
import SelectField from "../../../shared/SelectField"
import BrynsonDatePicker from "../../../shared/BrynsonDatePicker"
import CheckBoxExtended from "../../../shared/CheckboxExtended"

export default function PersonalSettings(params) {
  const { currentUserData } = useContext(GlobalContext);
  const { currentCompanyData, updateCurrentCompany } = useContext(GlobalContext)
  const [error, setError] = useState()
  const [loading, setLoading] = useState()
  const [data, setData] = useState()
  const communicationPreferenceGridRef = useRef()
  const managementSoftwaresGridRef = useRef()
  const brynsonOnboardingGridRef = useRef()
  const clientAccessesGridRef = useRef()
  const ilsGridRef = useRef()
  const additionalILSGridRef = useRef()

  const isBrynsonUser = currentUserData?.currentUser?.email?.endsWith('@brynson.com')
  
  const defaultCommunicationPreferences = new Array(
    {type: 'E-mail', approved: false, order: 1},
    {type: 'Slack', approved: false, order: 2},
    {type: 'Phone', approved: false, order: 3}
  )

  const defaultManagementSoftwares = [
    'Property Management Software (PMS)',
    'Customer Relationship Manager (CRM)',
    'Leasing AI Tools:',
  ].map((type) => ({
    type,
    active: false,
    software: null,
    brynsonAccess: null,
    currentRep: null,
    repEmail: null,
    repPhone: null,
    login: null,
    password: null,
  }));

  const ILSFields = (type) => ({
    type,
    active: false,
    msa: false,
    brynson_package: null,
    brynson_direct_aor: null,
    currentRep: null,
    repEmail: null,
    repPhone: null,
    login: null,
    password: null,
  });

  const defaultILS = [
    'Apartments.com', 
    'Zillow.com',
    'Rent.com',
    'Apartmentlist.com',
    'Zumper.com',
    'Rentable.com'
  ].map(ILSFields)

  const defaultAdditionalILS = [
    'Apartments.com (like Campus Network)', 
    'Rent.com (like RentSocial, RentSearch)',
    'Zumper.com (Amplify, Review Removal)',
  ].map(ILSFields)
  
  const contractTypeOptions = ["Standard", "Professional", "Enterprise", "Custom"].map((x) => ({value: x, label: x}))
  const PMSOptions = ['Not Applicable', 'AppFolio', 'Buildium', 'Yardi Voyager', 'TenantCloud', 'RealPage', 'Entrata', 'ResMan', 'Remote Landlord', 'Turbo Tenant', 'XML / Custom Feed']
  const CRMOptions = ['Not Applicable', 'Knock CRM', 'Yardi CRM', 'RentCafe', 'I Love Leasing', 'CRMIQ', 'MaxLeases', 'Mail Chimp', 'Beehive']
  const brynsonAccessOptions = ['Not Applicable', 'Full Admin', 'Partial Access', 'No Access']
  const IAOptions = ['Not Applicable', 'Meet Elise', 'Lea Pro']

  const ilsGridColumns = [
    {field: 'type', headerName: 'Type'},
    {field: 'active', headerName: 'Active', editable: true},
    {field: 'msa', headerName: 'MSA', editable: true},
    ...(isBrynsonUser
      ? [{
          field: 'brynson_package',
          headerName: 'Brynson Package Type',
          cellEditor: 'agSelectCellEditor',
          singleClickEdit: true,
          editable: true,
          cellEditorParams: {
            values: ['Not Applicable', 'Standard', 'Professional', 'Enterprise'],
          },
        }]
      : []),
    {
      field: 'brynson_direct_aor',
      headerName: 'Brynson Direct / AOR',
      cellEditor: 'agSelectCellEditor',
      singleClickEdit : true,
      editable: true,
      cellEditorParams: {
        values: ['Brynson Direct', 'Agency of Record']
      }
    },
    {field: 'currentRep', headerName: 'Current Rep', editable: true},
    {field: 'repEmail', headerName: 'Rep E-mail', editable: true},
    {field: 'repPhone', headerName: 'Rep Phone Number', editable: true},
    {field: 'login', headerName: 'Login', editable: true},
    {field: 'password', headerName: 'Password', editable: true},
  ]

  useEffect(() => {
    if (currentCompanyData) {
      const currentPreferences = Array.from(currentCompanyData.currentUser.currentCompany.communicationPreference);
      const mergedCommunicationPreferences = defaultCommunicationPreferences.map(defaultPref => {
        const currentPref = currentPreferences.find(pref => pref.type === defaultPref.type);
        return currentPref ? currentPref : defaultPref;
      });

      const currentManagementSoftwares = currentCompanyData.currentUser.currentCompany.managementSoftwares;
      const mergedManagementSoftwares = defaultManagementSoftwares.map(defaultPref => {
        const currentPref = currentManagementSoftwares.find(pref => pref.type === defaultPref.type);
        return currentPref ? currentPref : defaultPref;
      });

      const currentILS = currentCompanyData.currentUser.currentCompany.ils;
      const mergedILS = defaultILS.map(defaultPref => {
        const currentPref = currentILS.find(pref => pref.type === defaultPref.type);
        return currentPref ? currentPref : defaultPref;
      });

      const currentAdditionalILS = currentCompanyData.currentUser.currentCompany.additionalIls;
      const mergedAdditionalILS = defaultAdditionalILS.map(defaultPref => {
        const currentPref = currentAdditionalILS.find(pref => pref.type === defaultPref.type);
        return currentPref ? currentPref : defaultPref;
      });
      
      setData({
        data, 
        ...currentCompanyData.currentUser.currentCompany,
        communicationPreference: mergedCommunicationPreferences,
        managementSoftwares: mergedManagementSoftwares,
        ils: mergedILS,
        additionalIls: mergedAdditionalILS,
      })
    }
  }, [currentCompanyData])
  
  const breadCrumbs = [
    { link: "/portal/dashboard", title: "dashboard" },
    { link: "/portal/settings", title: "settings" },
    { link: "/portal/settings/company", title: "Company" },
  ]

  const save = () => {
    setLoading(true)
    const payload = {...data}
    payload.contract.start_date = data.contract.start_date && moment(data.contract.start_date).format('YYYY-MM-DD')
    payload.contract.end_date = data.contract.end_date && moment(data.contract.end_date).format('YYYY-MM-DD')

    const communicationPreference = [];
    communicationPreferenceGridRef.current.api.forEachNode(node => { 
      communicationPreference.push(node.data);
    });
    payload.communicationPreference = communicationPreference

    const managementSoftwares = [];
    managementSoftwaresGridRef.current.api.forEachNode(node => { 
      managementSoftwares.push(node.data);
    });
    payload.managementSoftwares = managementSoftwares

    const ils = [];
    ilsGridRef.current.api.forEachNode(node => { 
      ils.push(node.data);
    });
    payload.ils = ils


    payload.brynsonOnboarding = brynsonOnboardingGridRef.current.getData()
    payload.clientAccesses = clientAccessesGridRef.current.getData()
    
    updateCurrentCompany(payload).then((result) => {
      if (result?.error || result?.data?.editProperty?.errors) {
        setError(`Error creating client activity: ${result?.error || result?.data?.editProperty?.errors}`)
      }
      setLoading(false)
    })
  }

  return (
    <div className="p-8">
      { data && <div>
        <BreadCrumbs items={breadCrumbs} />
        <DesktopH5 content={"Edit Company Settings"} />
        {error && <p className="text-red-600">{error}</p>}
        <div className="flex row flex-wrap grid grid-cols-3 gap-3">
          <div className="mb-2 w-full">
            <label htmlFor="company-settings-about">Name</label>
            <InputField
              id={'company-settings-about'}
              placeholder='Name'
              defaultValue={data.about}
              type="text"
              onChange={(input) => {
                data.about = input.target.value
              }}
            />
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="company-settings-office-address">Office Address</label>
            <InputField
              id={'company-settings-office-address'}
              placeholder='Office Address'
              defaultValue={data.officeAddress}
              type="officeAddress"
              onChange={(input) => {
                data.officeAddress = input.target.value
              }}
            />
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="company-settings-website">Website</label>
            <InputField
              id={'company-settings-website'}
              placeholder='Website'
              defaultValue={data.website}
              type="website"
              onChange={(input) => {
                data.website = input.target.value
              }}
            />
          </div>
        </div>

        <div>
          <h3 className="mt-4 mb-2 text-bblue font-medium">Contract</h3>
          <div className="flex row flex-wrap grid grid-cols-3 gap-3">
            <div className="mb-2 w-full">
              <label htmlFor="company-settings-contract-type">Type</label>
              <SelectField
                options={contractTypeOptions}
                id={'company-settings-contract-type'}
                defaultValue={data.contract.type ? contractTypeOptions.find(o => o.value === data.contract.type) : null }
                onChange={(value) => {
                  data.contract.type = value
                }}
              />
            </div>
            <div className="mb-2 w-full">
              <label htmlFor="company-settings-contract-start-date">Start Date</label>
              <BrynsonDatePicker
                id={'company-settings-contract-start-date'}
                placeholder='Start Date'
                selectedDate={
                  data.contract.start_date
                  ? new Date(`${data.contract.start_date}T00:00:00`)
                  : null
                }
                onChange={(date) => data.contract.start_date = date}
              />
            </div>
            <div className="mb-2 w-full">
              <label htmlFor="company-settings-website">End Date</label>
              <BrynsonDatePicker
                id={'company-settings-contract-end-date'}
                placeholder='End Date'
                selectedDate={
                  data.contract.end_date
                  ? new Date(`${data.contract.end_date}T00:00:00`)
                  : null
                }
                onChange={(date) => data.contract.end_date = date}
              />
            </div>
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="company-settings-contract-link">Link</label>
            <InputField
              id={'company-settings-contract-link'}
              placeholder='Contract Link'
              defaultValue={data.contract.link}
              onChange={(input) => {
                data.contract.link = input.target.value
              }}
            />
          </div>
        </div>

        <div>
          <h3 className="mt-4 mb-2 text-bblue font-medium">Portfolio Overview</h3>
          <div className="mb-2 w-full">
            <div className="flex">
              <CheckBoxExtended 
                id={'company-settings-contract-portolio-verified'}
                checked={data.portfolio.verified}
                handleClick={(value) => {
                  data.portfolio.verified = value
                }}
              />
              <label htmlFor="company-settings-contract-portolio-verified" className="ml-2">Verified</label>
            </div>
          </div>
          <div className="flex row flex-wrap grid grid-cols-2 gap-2">
            <div className="mb-2 w-full">
              <label htmlFor="company-settings-portfolio-property-count">Property Count</label>
              <InputField
                id={'company-settings-portfolio-property-count'}
                placeholder='Property Count'
                defaultValue={data.portfolio.property_count}
                onChange={(input) => {
                  data.portfolio.property_count = input.target.value
                }}
              />
              
            </div>
            <div className="mb-2 w-full">
              <label htmlFor="company-settings-portfolio-link">Link</label>
              <InputField
                id={'company-settings-portfolio-link'}
                placeholder='Link'
                defaultValue={data.portfolio.link}
                onChange={(input) => {
                  data.portfolio.link = input.target.value
                }}
              />
            </div>
          </div>
        </div>

        <div>
          <h3 className="mt-4 mb-2 text-bblue font-medium">Contacts</h3>
          <ContactForm OpenModalComponent={({ onClick }) => (
            <a
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
              className="text-bblue underline cursor-pointer"
            >
              Add new contact
            </a>
            )}>
          </ContactForm>
          <div className="ag-theme-alpine mt-4" style={{ height: '200px', width: '100%' }}>
            <ContactGrid />
          </div>
        </div>

        <div>
          <h3 className="mt-4 mb-2 text-bblue font-medium">Communication Preference Type</h3>
          <div className="ag-theme-alpine mt-4" style={{ height: '200px', width: '100%' }}>
            <AgGridReact
              rowData={data.communicationPreference}
              ref={communicationPreferenceGridRef}
              columnDefs={[
                {field: 'type', headerName: 'Type'},
                {field: 'approved', headerName: 'Approved Type', editable: true},
                {
                  field: 'order',
                  headerName: 'Order of Preference',
                  cellEditor: 'agSelectCellEditor',
                  singleClickEdit : true,
                  editable: true,
                  cellEditorParams: {
                    values: [1, 2, 3],
                  },
                  valueFormatter: item => ({ 1: "1st", 2: "2nd", 3: "3rd" }[item.value]) || item.value,
                }
              ]}
              autoSizeStrategy={{type: "fitCellContents"}}
            />
          </div>
        </div>

        <div>
          <h3 className="mt-4 mb-2 text-bblue font-medium">Management Software</h3>
          <div className="ag-theme-alpine mt-4" style={{ height: '200px', width: '100%' }}>
            <AgGridReact
              rowData={data.managementSoftwares}
              ref={managementSoftwaresGridRef}
              columnDefs={[
                {field: 'type', headerName: 'Type'},
                {field: 'active', headerName: 'Active', editable: true},
                {
                  field: 'software_name',
                  headerName: 'Software Name',
                  cellEditor: 'agSelectCellEditor',
                  singleClickEdit : true,
                  editable: true,
                  cellEditorParams: params => {
                    return {values: {0: PMSOptions, 1: CRMOptions, 2: IAOptions}[params.rowIndex]}
                  }
                },
                {
                  field: 'brynson_access',
                  headerName: 'Brynson Access',
                  cellEditor: 'agSelectCellEditor',
                  singleClickEdit : true,
                  editable: true,
                  cellEditorParams: {
                    values: brynsonAccessOptions,
                  },
                },
                {field: 'currentRep', headerName: 'Current Rep', editable: true},
                {field: 'repEmail', headerName: 'Rep E-mail', editable: true},
                {field: 'repPhone', headerName: 'Rep Phone Number', editable: true},
                {field: 'login', headerName: 'Login', editable: true},
                {field: 'password', headerName: 'Password', editable: true},
              ]}
              autoSizeStrategy={{type: "fitCellContents"}}
            />
          </div>
        </div>

        <div>
          <h3 className="mt-4 mb-2 text-bblue font-medium">Internet Listing Services (ILS)</h3>
          <div className="ag-theme-alpine mt-4" style={{ height: '310px', width: '100%' }}>
            <AgGridReact
              rowData={data.ils}
              ref={ilsGridRef}
              columnDefs={ilsGridColumns}
              autoSizeStrategy={{type: "fitCellContents"}}
            />
          </div>
        </div>

        <div>
          <h3 className="mt-4 mb-2 text-bblue font-medium">Additional ILS Products</h3>
          <div className="ag-theme-alpine mt-4" style={{ height: '200px', width: '100%' }}>
            <AgGridReact
              rowData={data.additionalIls}
              ref={additionalILSGridRef}
              columnDefs={ilsGridColumns}
              autoSizeStrategy={{type: "fitCellContents"}}
            />
          </div>
        </div>

        {
          isBrynsonUser && (
            <div>
              <h3 className="mt-4 mb-2 text-bblue font-medium">Brynson Onboarding</h3>
              <div className="ag-theme-alpine" style={{ height: "600px", width: "100%" }}>
                <OnboardingGrid ref={brynsonOnboardingGridRef} items={data.brynsonOnboarding} />
              </div>
            </div>
          )
        }
        

        <div>
          <h3 className="mt-4 mb-2 text-bblue font-medium">Brynsights Users</h3>
          <ClientAccessForm OpenModalComponent={({ onClick }) => (
            <a
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
              className="text-bblue underline cursor-pointer"
            >
              Add new user
            </a>
            )}>
          </ClientAccessForm>
          <div className="ag-theme-alpine mt-4" style={{ height: '200px', width: '100%' }}>
            <ClientAccessGrid ref={clientAccessesGridRef} items={data.clientAccesses} />
          </div>
        </div>
        
        <div className="flex justify-center items-center w-full mt-6">
          <div>
            <BrynsonSquareButton
              type="primary"
              loading={loading}
              onClick={save}
              label="Edit Company settings"
            />
          </div>
        </div>
      </div> }
    </div>
  )
}