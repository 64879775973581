import React, { useEffect, useState } from "react"
import { useQuery } from "urql"
import { exportCSV } from "../../paul/PaulChartUtils"
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import AGGridTable from "../../shared/AGGridTable"
import { ILS_LEADS_QUERY } from "../../queries/IlsPerformanceMatricsQueries"

const IlsLeadsTable = ({ selectedTab, queryVariables }) => {
  const [leadData, setLeadData] = useState([])
  const [analytics, setAnalytics] = useState({
    totalLeads: 0,
    leadsTally: {}
  })
  const [uniqueLeadTypes, setUniqueLeadTypes] = useState([])

  const columnDefs = [
    { title: "Property Name", accessor: "propertyName", type: "string" },
    { title: "Lead Date", accessor: "leadDate", type: "string" },
    { title: "Name", accessor: "name", type: "string" },
    { title: "Email", accessor: "email", type: "string" },
    { title: "Phone", accessor: "phone", type: "string" },
    { title: "Message", accessor: "message", type: "string" },
    { title: "Lead Type", accessor: "leadType", type: "string" }
  ]

  const [result] = useQuery({
    query: ILS_LEADS_QUERY,
    variables: queryVariables,
    requestPolicy: "network-only",
    skip: !queryVariables
  })

  const { data, fetching, error } = result

  useEffect(() => {
    if (data) {
      console.log("GraphQL Response:", data)
      // Get unique lead types
      const allLeadTypes = new Set(data.ilsLeads.map(lead => lead.leadType))
      setUniqueLeadTypes(Array.from(allLeadTypes))

      const leadsTally = data.ilsLeads.reduce((counts, lead) => {
        const { leadType, propertyName } = lead

        counts[leadType] = (counts[leadType] || 0) + 1
        counts.properties = counts.properties || []
        counts.properties[propertyName] = counts.properties[propertyName] || {
          totalLeads: 0,
          leadTypes: {}
        }
        counts.properties[propertyName].totalLeads += 1
        counts.properties[propertyName].leadTypes[leadType] = (counts.properties[propertyName].leadTypes[leadType] || 0) + 1

        return counts
      }, {})
      setLeadData(data.ilsLeads)
      setAnalytics({
        totalLeads: data.ilsLeads.length,
        leadsTally: leadsTally
      })
    }
  }, [data])

  if (fetching) return <div>Loading leads...</div>
  if (error) return <div>Error loading leads: {error.message}</div>
  if (!data || !data.ilsLeads) return <div>No data available.</div>

  // Column definitions for properties table with dynamic lead type columns
  const propertiesColumnDefs = [
    { title: "Property Name", accessor: "propertyName", type: "string" },
    { title: "Total Leads", accessor: "totalLeads", type: "number" },
    ...uniqueLeadTypes.map(leadType => ({
      title: leadType.charAt(0).toUpperCase() + leadType.slice(1),
      accessor: `leadTypes.${leadType}`,
      type: "number"
    }))
  ]

  // Transform properties data with separate columns for each lead type
  const propertiesData = Object.entries(analytics.leadsTally.properties || {}).map(([propertyName, data]) => ({
    propertyName,
    totalLeads: data.totalLeads,
    leadTypes: data.leadTypes,
    ...Object.fromEntries(
      uniqueLeadTypes.map(leadType => [
        `leadTypes.${leadType}`,
        data.leadTypes[leadType] || 0
      ])
    )
  }))

  return (
    <div>
      {/* Table for Properties Data */}
      <div className="mb-6">
        <div className="flex justify-between">
          <h3 className="text-xl font-semibold mb-4">Property Leads Summary</h3>
          <button
            onClick={() => exportCSV(propertiesData, `${selectedTab} Data Export`)}
            className="ml-4 text-bblue flex items-center font-medium"
          >
            Download CSV
            <ArrowDownTrayIcon className="ml-2 h-5 w-5" />
          </button>
        </div>
        <AGGridTable data={propertiesData} headerColumns={propertiesColumnDefs} height="350px"/>
      </div>

      <div className="mb-6">
        <div className="flex justify-between">
          <h3 className="text-xl font-semibold mb-4">All Properties Info</h3>
          <button
            onClick={() => exportCSV(leadData, `${selectedTab} Data Export`)}
            className="ml-4 text-bblue flex items-center font-medium"
          >
            Download CSV
            <ArrowDownTrayIcon className="ml-2 h-5 w-5" />
          </button>
        </div>

        <AGGridTable data={leadData || []} headerColumns={columnDefs} />
      </div>
    </div>
  )
}

export default IlsLeadsTable
