import React from 'react';
import { ArrowDownTrayIcon, TableCellsIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import AGGridTable from '../shared/AGGridTable';
import { exportCSV } from './PaulChartUtils';

const PaulReportViewer = ({
  data,
  columns,
  sections = [],
  onSaveReport,
  title,
  sql
}) => {
  const headerColumns = columns.map(col => ({
    title: col.headerName || col.field.split('_').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' '),
    accessor: col.field,
    type: col.type,
    minWidth: 150
  }));

  const calculateHeight = () => {
    const rowHeight = 48;
    const baseHeight = 150;
    const height = baseHeight + (data.length * rowHeight);
    const finalHeight = Math.max(350, Math.min(height, 800));
    return finalHeight;
  };

  const gridHeight = `${calculateHeight()}px`;

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
      <div className="p-4 border-b flex justify-between items-center">
        <div className="flex items-center gap-2">
          <TableCellsIcon className="h-5 w-5 text-gray-500" />
          <h3 className="font-medium">{title || 'Report View'}</h3>
        </div>
        <div className="flex items-center gap-3">
          <button
            onClick={() => onSaveReport(sql)}
            className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-700 text-sm"
          >
            Save Report
          </button>
          <button
            onClick={() => exportCSV(data, title || 'Report Export', columns)}
            className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-700 text-sm"
          >
            <ArrowDownTrayIcon className="h-4 w-4" />
            Export
          </button>
        </div>
      </div>

      {sections.some(section => section.status !== 'success') && (
        <div className="p-4 bg-yellow-50 border-b">
          <div className="flex items-start">
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-600 mt-0.5" />
            <div className="ml-3">
              <h4 className="text-sm font-medium text-yellow-800">
                Some data may be incomplete
              </h4>
              <div className="mt-1 text-sm text-yellow-700">
                {sections.map((section, idx) => (
                  section.status !== 'success' && (
                    <div key={idx} className="mt-1">
                      {section.name}: {section.message || 'Incomplete data'}
                    </div>
                  )
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <div style={{ height: gridHeight, width: '100%' }}>
        <AGGridTable
          data={data}
          headerColumns={headerColumns}
          height={gridHeight}
          customCellRenderers={{}}
          customHeaderRenderers={{}}
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true
          }}
        />
      </div>
    </div>
  );
};

export default PaulReportViewer;