import moment from "moment-timezone"

export function splitString({ string, numberWords }) {
  if (string.length > 0) {
    const stringArray = string.split(",")
    if (stringArray.length > 0) {
      if (stringArray[1] === "United States of America") {
        return stringArray[0]
      } else if (stringArray.slice(0, numberWords).join().length > 18) {
        return stringArray.slice(0, numberWords).join().substring(0, 18)
      } else {
        return stringArray.slice(0, numberWords).join()
      }
    }
  }
  return ""
}

export function formatPhone(phone) {
  const cleaned = ("" + phone).replace(/\D/g, "")
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? "+1 " : ""
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
  }
}

export function isClientValid(clientInput, clientPhone, companyPhone, companyUser) {
  const { firstName, email, phone } = clientInput
  return (
    firstName?.trim() !== "" &&
    (email?.trim() !== "" || phone?.trim() !== "") &&
    (clientPhone !== companyPhone || !companyPhone) &&
    email?.trim() !== companyUser?.email
  )
}

export function formatPrice(price) {
  if (price === 0) {
    return (0).toLocaleString("en-US", { style: "currency", currency: "USD" })
  } else {
    return (price / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })
  }
}

export function unformatPrice(string) {
  return parseFloat(string.replace(/[^0-9-.]/g, "")) * 100
}

export function formatDate(date) {
  return moment(date).format("MMMM Do YYYY [at] h:mm a")
}

export function formatShortDate(date) {
  return moment(date).format("MM/DD/YYYY")
}

export function companyToBusinessHours(companyHours) {
  const businessHours = []

  const weekDayKey = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  companyHours.forEach((d) => {
    if (d.hours.startsAt && d.hours.endsAt) {
      businessHours.push({
        daysOfWeek: [weekDayKey.indexOf(d.weekDay)],
        startTime: d.hours.startsAt,
        endTime: d.hours.endsAt
      })
    }
  })

  return businessHours
}

export function selectStyling() {
  const options = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),

    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #E4EFF6",
      padding: 16,
      backgroundColor: state.isSelected || state.isFocused ? "#EFF6FA" : "#fff",
      color: "#333438"
    }),
    valueContainer: (provided) => ({ ...provided, padding: "8px 16px" }),
    input: (provided) => ({ ...provided, color: "#333438", fontSize: "16px" }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: "0.25rem",
      boxShadow: state.isSelected || state.isFocused ? "0 0 0 1px #0D9DA4" : "none",
      borderColor: state.isSelected || state.isFocused ? "#0D9DA4" : "#c8d6df",
      "&:hover": {
        boxShadow: "0 0 0 1px #0D9DA4",
        borderColor: "#0D9DA4"
      }
    })
  }

  return options
}

export function getDisplayAndFullTimeZone(property) {
  let timeZoneDisplay = ""
  let timeZone = ""
  if (property?.kind === "virtual") {
    timeZoneDisplay = new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2]
    timeZone = new Date().toLocaleTimeString("en-us", { timeZoneName: "long" }).split(" ").slice(2).join(" ")
  } else {
    timeZoneDisplay = property?.timeZoneAbbr
    timeZone = property?.formattedTimeZone
  }

  return [timeZoneDisplay, timeZone]
}


export function validEmail(email) {
  return email.match(
    /^(([^<>()[\].,;:@"]+(\.[^<>()[\].,;:@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

export const usStates = [
  { name: "Alabama", value: "alabama", label: "Alabama" },
  { name: "Alaska", value: "alaska", label: "Alaska" },
  { name: "Arizona", value: "arizona", label: "Arizona" },
  { name: "Arkansas", value: "arkansas", label: "Arkansas" },
  { name: "California", value: "california", label: "California" },
  { name: "Colorado", value: "colorado", label: "Colorado" },
  { name: "Connecticut", value: "connecticut", label: "Connecticut" },
  { name: "Delaware", value: "delaware", label: "Delaware" },
  { name: "Florida", value: "florida", label: "Florida" },
  { name: "Georgia", value: "georgia", label: "Georgia" },
  { name: "Hawaii", value: "hawaii", label: "Hawaii" },
  { name: "Idaho", value: "idaho", label: "Idaho" },
  { name: "Illinois", value: "illinois", label: "Illinois" },
  { name: "Indiana", value: "indiana", label: "Indiana" },
  { name: "Iowa", value: "iowa", label: "Iowa" },
  { name: "Kansas", value: "kansas", label: "Kansas" },
  { name: "Kentucky", value: "kentucky", label: "Kentucky" },
  { name: "Louisiana", value: "louisiana", label: "Louisiana" },
  { name: "Maine", value: "maine", label: "Maine" },
  { name: "Maryland", value: "maryland", label: "Maryland" },
  { name: "Massachusetts", value: "massachusetts", label: "Massachusetts" },
  { name: "Michigan", value: "michigan", label: "Michigan" },
  { name: "Minnesota", value: "minnesota", label: "Minnesota" },
  { name: "Mississippi", value: "mississippi", label: "Mississippi" },
  { name: "Missouri", value: "missouri", label: "Missouri" },
  { name: "Montana", value: "montana", label: "Montana" },
  { name: "Nebraska", value: "nebraska", label: "Nebraska" },
  { name: "Nevada", value: "nevada", label: "Nevada" },
  { name: "New Hampshire", value: "new_hampshire", label: "New Hampshire" },
  { name: "New Jersey", value: "new_jersey", label: "New Jersey" },
  { name: "New Mexico", value: "new_mexico", label: "New Mexico" },
  { name: "New York", value: "new_york", label: "New York" },
  { name: "North Carolina", value: "north_carolina", label: "North Carolina" },
  { name: "North Dakota", value: "north_dakota", label: "North Dakota" },
  { name: "Ohio", value: "ohio", label: "Ohio" },
  { name: "Oklahoma", value: "oklahoma", label: "Oklahoma" },
  { name: "Oregon", value: "oregon", label: "Oregon" },
  { name: "Pennsylvania", value: "pennsylvania", label: "Pennsylvania" },
  { name: "Rhode Island", value: "rhode_island", label: "Rhode Island" },
  { name: "South Carolina", value: "south_carolina", label: "South Carolina" },
  { name: "South Dakota", value: "south_dakota", label: "South Dakota" },
  { name: "Tennessee", value: "tennessee", label: "Tennessee" },
  { name: "Texas", value: "texas", label: "Texas" },
  { name: "Utah", value: "utah", label: "Utah" },
  { name: "Vermont", value: "vermont", label: "Vermont" },
  { name: "Virginia", value: "virginia", label: "Virginia" },
  { name: "Washington", value: "washington", label: "Washington" },
  { name: "West Virginia", value: "west_virginia", label: "West Virginia" },
  { name: "Wisconsin", value: "wisconsin", label: "Wisconsin" },
  { name: "Wyoming", value: "wyoming", label: "Wyoming" }
]
