import React, { useState, useContext } from "react"
import styled from "styled-components"
import BrynsonSquareButton from "../../shared/BrynsonSquareButton"
import Modal from "../../shared/Modal"
import DesktopH5 from "../../shared/DesktopH5"
import InputField from "../../shared/InputField"
import CurrencyInput from "../../shared/CurrencyInput"
import BrynsonDatePicker from "../../shared/BrynsonDatePicker"

import { OffersContext } from "../../context/OffersContext"

export default function OfferForm({ item = {}, OpenModalComponent = null}) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentInput, setCurrentInput] = useState(
    {...item, ...{description: '', term: '', effectiveRent: '', startDate: '', endDate: ''}
  })
  const { createOffer, updateOffer, deleteOffer } = useContext(OffersContext)

  const title = item?.id ? 'Edit Offer' : 'Add Offer'

  console.log(item, currentInput)

  const DefaultOpenModalComponent = ({ onClick, label }) => (
    <a className="font-medium text-bblue underline" onClick={onClick}>{label}</a>
  );
  const OpenComponent = OpenModalComponent || DefaultOpenModalComponent;

  const addValue = (field, value) => {
    const current = {}
    current[field] = value
    setCurrentInput({ ...currentInput, ...current })
  }

  var getFloatValue = (value = '') => {
    var numericValue = value
      .replace("$", "")
      .replace(/,/g, "")
      .trim();

    return parseFloat(numericValue) || 0;
  };

  const save = () => {
    console.log('save', currentInput)
    setLoading(true)
    const payload = {
      ...currentInput,
      effectiveRent: getFloatValue(currentInput.effectiveRent),
    };


    if(payload.id) {
      updateOffer(payload).then((result) => {
        if (result?.error || result?.data?.offer?.errors) {
          setError(`Error updating offer info: ${result?.error || result?.data?.offer?.errors}`)
        } else {
          setOpen(false)
        }
        setLoading(false)
      })
    } else {
      createOffer(payload).then((result) => {
        if (result?.error || result?.data?.offer?.errors) {
          setError(`Error updating offer info: ${result?.error || result?.data?.offer?.errors}`)
        } else {
          setOpen(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <>
      <OpenComponent onClick={() => setOpen(true)} label={item?.name} />
      <Modal open={open} key='property-info-form-model' closeModal={() => setOpen(false)}
        header={
          <>
            <DesktopH5 content={title} />
          </>
        }
        footer={
          <>
            <BrynsonSquareButton
              type="tertiary"
              onClick={() => setOpen(false)}
              label="Cancel"
            />
            <BrynsonSquareButton
              type="primary"
              testId="add-new-property-button"
              loading={loading}
              onClick={save}
              label="Save"
            />
          </>
        }>
        <div className="mb-2 mx-2">
          {error && <p className="text-red-600 mb-2">{error}</p>}
          <div className="mb-2 w-full">
            <label htmlFor="offer-form-description">Description</label>
            <InputField
              id="offer-form-description"
              placeholder="Description"
              defaultValue={item.description}
              onChange={(e) => addValue("description", e.target.value)}
            />
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="offer-form-effective_rent">Effective Rent</label>
            <CurrencyInput
              id="offer-form-effective_rent"
              placeholder="Effective Rent"
              defaultValue={item.effectiveRent}
              onChange={(input) => {
                addValue("effective_rent", input.target.value)
              }}
            />
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="offer-form-term">Term</label>
            <InputField
              id="offer-form-term"
              placeholder="Term"
              defaultValue={item.term}
              type="number"
              step="1"
              onChange={(e) => addValue("term", parseInt(e.target.value))}
            />
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="effective-from">Start Date</label>
            <BrynsonDatePicker
              selectedDate={item.startDate ? new Date(item.startDate) : null}
              onChange={(date) => addValue("startDate", date)}
            />
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="effective-from">End Date</label>
            <BrynsonDatePicker
              selectedDate={item.endDate ? new Date(item.endDate) : null}
              onChange={(date) => addValue("endDate", date)}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}