import React, { useImperativeHandle, forwardRef, useRef } from "react";
import { AgGridReact } from "ag-grid-react";

const defaultData = [
  {path: ['Welcome Email', 'Sent'], active: false},
  {path: ['Agency of Record (AOR) Email', 'Sent'], active: false},
  {path: ['Slack Channel', 'Created'], active: false},
  {path: ['Client Email', 'Created'], active: false},
  {path: ['Client Folder Setup', 'Account Management Internal (Folder)'], active: false},
  {path: ['Client Folder Setup', 'Billing (Folder)'], active: false},
  {path: ['Client Folder Setup', 'Brynson Agreements (Folder)'], active: false},
  {path: ['Client Folder Setup', 'ILS Agreements (Folder)'], active: false},
  {path: ['Client Folder Setup', 'Shared Folder', 'Account Management Internal (Folder)'], active: false},
  {path: ['Client Folder Setup', 'Shared Folder', 'CLIENT NAME / BRYNSON SHARED FOLDER (Only folder shared to the clients)', 'Digital Curb Appeal Audits (Folder)'], active: false},
  {path: ['Client Folder Setup', 'Shared Folder', 'CLIENT NAME / BRYNSON SHARED FOLDER (Only folder shared to the clients)', 'Quarterly Audits (Folder)'], active: false},
  {path: ['Client Folder Setup', 'Shared Folder', 'CLIENT NAME / BRYNSON SHARED FOLDER (Only folder shared to the clients)', 'Client Master Lead Tracking Details List'], active: false},
  {path: ['Client Folder Setup', 'Shared Folder', 'CLIENT NAME / BRYNSON SHARED FOLDER (Only folder shared to the clients)', 'Client Onboarding Info Sheet - Master'], active: false},
  {path: ['Client Folder Setup', 'Shared Folder', 'CLIENT NAME / BRYNSON SHARED FOLDER (Only folder shared to the clients)', 'Client Property Directory'], active: false},
  {path: ['Client Folder Setup', 'Shared Folder', 'CLIENT NAME / BRYNSON SHARED FOLDER (Only folder shared to the clients)', 'Photoshoots and Benefits'], active: false},
  {path: ['Client Folder Setup', 'Client Activity Log'], active: false},
  {path: ['Client Folder Setup', 'Client Profile Sheet'], active: false},
  {path: ['Client Folder Setup', 'Client Property Directory'], active: false},
  {path: ['Client Folder Setup', 'Photoshoot and Benefits'], active: false},
  {path: ['Client Folder Setup', 'Apartments.com'], active: false},
  {path: ['Client Folder Setup', 'Zillow.com'], active: false},
  {path: ['Client Folder Setup', 'Rent.com'], active: false},
  {path: ['Client Folder Setup', 'Apartmentlist.com'], active: false},
  {path: ['Client Folder Setup', 'Zumper.com'], active: false},
]

const OnboardingGrid = forwardRef((props, ref) => {
  const gridRef = useRef()
  const mergedData = defaultData.map(defaultPref => {
    const currentPref = props.items.find(pref => pref.path.join() === defaultPref.path.join());
    return currentPref ? currentPref : defaultPref;
  });

  useImperativeHandle(ref, () => ({
    getData: () => {
      const data = [];
      gridRef.current.api.forEachNode(node => {
        if (node.data) {
          data.push(node.data);
        }
      });

      return data
    },
  }));
  
  return (
    <AgGridReact
      ref={gridRef}
      treeData={true}
      defaultColDef={{flex: 1}}
      rowData={mergedData}
      columnDefs={[
        {
          field: "active",
          headerName: "",
          editable: true
        },
      ]}
      groupDefaultExpanded={-1}
      getDataPath={(data) => data.path}
      autoGroupColumnDef={{
        headerName: "Name",
        minWidth: 280,
        cellRendererParams: {
          suppressCount: true,
        },
      }}
    />
  );
})

export default OnboardingGrid