import React, { useEffect } from "react"
import OffersProvider from "../../context/OffersContext"
import OfferShow from "./OfferShow"
import OfferEdit from "./OfferEdit"

export default function Offer({ params, page }) {
  return (
    <OffersProvider>
      {page === "index" &&
        <OfferShow params={params} />
      }
      {page === "edit" &&
        <OfferEdit params={params} />
      }
    </OffersProvider>
  )
}
