import React, { useState } from "react"
import { useMutation } from "urql";
import styled from "styled-components"
import BrynsonSquareButton from "../../../shared/BrynsonSquareButton"
import Modal from "../../../shared/Modal"
import DesktopH5 from "../../../shared/DesktopH5"
import InputField from "../../../shared/InputField"
import SelectField from "../../../shared/SelectField"
import { CREATE_CONTACT_MUTATION, UPDATE_CONTACT_MUTATION } from "../../../queries/ContactsMutations";

export default function ContactForm({ item = {}, OpenModalComponent = null}) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentInput, setCurrentInput] = useState(
    {...{name: '', title: '', kind: '', email: '', phone: '', ...item}
  })
  
  const kindOptions = [
    {value: 'owner', label: 'Primary Contact (Owner)'},
    {value: 'main', label: 'Main Contact (Day-to-Day)'},
    {value: 'other', label: 'Other Contact'},
  ]

  const title = item?.id ? 'Edit Contact' : 'Add Contact'

  const DefaultOpenModalComponent = ({ onClick, label }) => (
    <a className="font-medium text-bblue underline" onClick={onClick}>{label}</a>
  );
  const OpenComponent = OpenModalComponent || DefaultOpenModalComponent;

  const addValue = (field, value) => {
    const current = {}
    current[field] = value
    setCurrentInput({ ...currentInput, ...current })
  }

  const [, createContact] = useMutation(CREATE_CONTACT_MUTATION)
  const [, updateContact] = useMutation(UPDATE_CONTACT_MUTATION)
  const save = () => {
    setLoading(true)
    const payload = currentInput

    if(payload.id) {
      updateContact(payload).then((result) => {
        if (result?.error || result?.data?.contact?.errors) {
          setError(`Error updating contact: ${result?.error || result?.data?.contact?.errors}`)
        } else {
          setOpen(false)
        }
        setLoading(false)
      })
    } else {
      createContact(payload).then((result) => {
        if (result?.error || result?.data?.contact?.errors) {
          setError(`Error creating contact: ${result?.error || result?.data?.contact?.errors}`)
        } else {
          setOpen(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <>
      <OpenComponent onClick={() => setOpen(true)} label={item?.name} />
      <Modal open={open} key='property-info-form-model' closeModal={() => setOpen(false)}
        header={
          <>
            <DesktopH5 content={title} />
          </>
        }
        footer={
          <>
            <BrynsonSquareButton
              type="tertiary"
              onClick={() => setOpen(false)}
              label="Cancel"
            />
            <BrynsonSquareButton
              type="primary"
              testId="add-new-property-button"
              loading={loading}
              onClick={save}
              label="Save"
            />
          </>
        }>
        <div className="my-4 mx-2">
          {error && <p className="text-red-600 mb-2">{error}</p>}
          <div className="mb-2 w-full">
            <label htmlFor="contact-kind">Type</label>
            <div className="mb-2 w-full">
              <SelectField
                options={kindOptions}
                id={'contact-kind'}
                defaultValue={item.kind ? kindOptions.find(x => x.value == item.kind) : null }
                onChange={(value) => addValue("kind", value)}
              />
            </div>
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="contact-name">Name</label>
            <div className="mb-2 w-full">
              <InputField
                id={'contact-name'}
                placeholder='Name'
                defaultValue={item.name}
                onChange={(e) => addValue("name", e.target.value)}
              />
            </div>
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="contact-title">Title</label>
            <div className="mb-2 w-full">
              <InputField
                id={'contact-title'}
                placeholder='Title'
                defaultValue={item.title}
                onChange={(e) => addValue("title", e.target.value)}
              />
            </div>
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="contact-email">E-mail</label>
            <div className="mb-2 w-full">
              <InputField
                id={'contact-email'}
                placeholder='E-mail'
                defaultValue={item.email}
                type={'email'}
                onChange={(e) => addValue("email", e.target.value)}
              />
            </div>
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="contact-phone">Phone</label>
            <div className="mb-2 w-full">
              <InputField
                id={'contact-phone'}
                placeholder='Phone'
                type={'tel'}
                defaultValue={item.phone}
                onChange={(e) => addValue("phone", e.target.value)}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}