import React, { useMemo } from 'react';
import { exportCSV } from './PaulChartUtils';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import {
  renderBarChart,
  renderLineChart,
  renderPieChart,
  renderHorizontalBarChart,
  renderStackedBarChart,
  formatValue
} from './PaulChartRenderers';
import { AgGridReact as AGGridTable } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const SavedPaulChart = ({ chart }) => {
  const { data, chart_type: chartType, columns, config } = chart;

  const renderVisualization = (type, data, config, columns) => {
    switch (type) {
      case 'bar':
        return renderBarChart(data, config, columns);
      case 'line':
        return renderLineChart(data, config, columns);
      case 'pie':
        return renderPieChart(data, config, columns);
      case 'horizontal_bar':
        return renderHorizontalBarChart(data, config, columns);
      case 'stacked_bar':
        return renderStackedBarChart(data, config, columns);
      case 'table':
        return renderTable(data, columns);
      default:
        return renderBarChart(data, config, columns);
    }
  };

  const renderTable = (data, columns) => {
    const columnDefs = columns.map(col => ({
      field: col.field,
      headerName: col.headerName || col.field.split('_').map(word =>
        word.charAt(0).toUpperCase() + word.slice(1)
      ).join(' '),
      valueFormatter: params => formatValue(params.value, col.type),
      sortable: true,
      filter: true
    }));

    return (
      <div className="h-[600px] w-full">
        <div className="ag-theme-alpine h-full w-full">
          <AGGridTable
            rowData={data}
            columnDefs={columnDefs}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true
            }}
          />
        </div>
      </div>
    );
  };

  if (!data || !Array.isArray(data)) {
    return (
      <div className="p-4 text-red-500">
        Invalid data format for chart.
      </div>
    );
  }

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
      <div className="p-4 flex justify-between items-center font-montserrat text-sm">
        <button
          onClick={() => exportCSV(data, 'Brynsights Paul Export', columns)}
          className="text-bblue flex items-center font-medium"
        >
          Download CSV
          <ArrowDownTrayIcon className="ml-2 h-5 w-5" />
        </button>
      </div>

      <h3 className="font-semibold text-center text-lg mb-8">
        {chart.title}
      </h3>

      {renderVisualization(chartType, data, config, columns)}
    </div>
  );
};

export default SavedPaulChart;