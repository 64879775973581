import React from "react"
const comingsoon = require("../../../../../public/samples/coming_soon.gif")

export default function Digible() {
	return (
		<div className="p-8">
			<h1 className="font-semibold text-xl">Digible</h1>
			<p className="text-lg font-medium">Integrate with your marketing analytics provider</p>
			<h1 className="font-semibold text-lg text-center mt-8 italic">Coming Soon</h1>
			<div className="flex justify-center align-center mt-2">
				<img src={comingsoon} />
			</div>
		</div>
	)
}