export const CONTACTS_QUERY = `
  query Contacts{
    contacts {
      id
      name
      title
      kind
      title
      email
      phone
    }
  }
`


export const CONTACT_QUERY = `
  query Contact($id:ID!){
    contact(id:$id) {
      id
      name
      title
      kind
      email
      phone
    }
  }
`

export const CREATE_CONTACT_MUTATION = `
  mutation CreateContactMutation($name: String!, $title: String, $kind: String, $email: String, $phone: String) {
    createContactMutation(
      name: $name
      title: $title
      kind: $kind
      email: $email
      phone: $phone
    ) {
      id
      name
      title
      kind
      email
      phone
    }
  }
`

export const UPDATE_CONTACT_MUTATION = `
  mutation UpdateContactMutation($id: ID!, $name: String!, $title: String, $kind: String, $email: String, $phone: String) {
    updateContactMutation(
      id: $id
      name: $name
      title: $title
      kind: $kind
      email: $email
      phone: $phone
    ) {
      id
      name
      title
      kind
      email
      phone
    }
  }
`

export const DELETE_CONTACT_MUTATION = `
  mutation DeleteContactMutation($id: ID!) {
    deleteContactMutation(id: $id) {
      result
      errors
    }
  }
`
