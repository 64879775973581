import React, { useEffect, useState } from "react"
import { useQuery } from "urql"
import { ILS_LEADS_QUERY } from "../../queries/IlsPerformanceMatricsQueries"

const IlsLeads = ({ selectedTab, ytdSummary }) => {
  const [analytics, setAnalytics] = useState({
    totalLeads: 0,
    leadsTally: {}
  })

  const [result] = useQuery({
    query: ILS_LEADS_QUERY,
    variables: { ilsSource: selectedTab.toLowerCase() },
    requestPolicy: "network-only"
  })

  const { data, fetching, error } = result

  useEffect(() => {
    if (data) {
      console.log("GraphQL Response:", data)
      const leadsTally = data.ilsLeads.reduce((counts, lead) => {
        const { leadType, propertyName } = lead

        counts[leadType] = (counts[leadType] || 0) + 1
        counts.properties = counts.properties || []
        counts.properties[propertyName] = counts.properties[propertyName] || { totalLeads: 0, leadTypes: {} }
        counts.properties[propertyName].totalLeads += 1
        counts.properties[propertyName].leadTypes[leadType] = (counts.properties[propertyName].leadTypes[leadType] || 0) + 1

        return counts
      }, {})
      setAnalytics({
        totalLeads: data.ilsLeads.length,
        leadsTally: leadsTally
      })
    }
  }, [data])

  if (fetching) return <div>Loading leads...</div>
  if (error) return <div>Error loading leads: {error.message}</div>
  if (!data || !data.ilsLeads) return <div>No data available.</div>

  const totalProperties = Object.keys(analytics.leadsTally.properties || {}).length

  return (
    <div className="flex flex-wrap mb-6 -mx-2">
      <div className="p-2 w-1/4">
        <div className="bg-blue-100 p-6 rounded-lg shadow-md flex flex-col justify-between h-36">
          <h5 className="text-lg font-bold">Total Properties</h5>
          <p className="text-5xl font-semibold text-blue-600">{totalProperties}</p>
        </div>
      </div>
      {/* Total Leads Box */}
      <div className="p-2 w-1/4">
        <div className="bg-blue-100 p-6 rounded-lg shadow-md flex flex-col justify-between h-36">
          <h5 className="text-lg font-bold">Total Leads</h5>
          <p className="text-5xl font-semibold text-blue-600">{analytics.totalLeads || 0}</p>
        </div>
      </div>

      {/* Leads Tally Boxes */}
      {analytics.leadsTally && Object.entries(analytics.leadsTally).filter(([key]) => key !== "properties").map(([type, count], idx) => (
        <div key={idx} className="p-2 w-1/4">
          <div className="bg-blue-100 p-6 rounded-lg shadow-md flex flex-col justify-between h-36">
            <h5 className="text-lg font-bold">{type.charAt(0).toUpperCase() + type.slice(1)} Leads</h5>
            <p className="text-5xl font-semibold text-blue-600">{count}</p>
          </div>
        </div>
      ))}

      {/* ytdSummary Tally Boxes */}
      {ytdSummary && Object.entries(ytdSummary).map(([type, count], idx) => (
        <div key={idx} className="p-2 w-1/4">
          <div className="bg-blue-100 p-6 rounded-lg shadow-md flex flex-col justify-between h-36">
            <h5 className="text-lg font-bold">YTD {type.slice(5)}</h5>
            <p className="text-5xl font-semibold text-blue-600">{count}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default IlsLeads
