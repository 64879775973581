import React, { useState } from "react"
import { useQuery } from "urql";
import { AgGridReact } from "ag-grid-react";
import ContactForm from "./ContactForm"
import DeleteContactConfirmation from "./DeleteContactConfirmation"
import { CONTACTS_QUERY } from "../../../queries/ContactsMutations";
import { TrashIcon } from "@heroicons/react/24/solid"


export default function ContactGrid() {
  const [loading, setLoading] = useState(false)
  const [{ data: contactData }, contactReload] = useQuery({
    query: CONTACTS_QUERY,
		requestPolicy: "network-only",
    pause: false
  })
  
  const kindOptions = [
    {value: 'owner', label: 'Primary Contact (Owner)'},
    {value: 'main', label: 'Main Contact (Day-to-Day)'},
    {value: 'other', label: 'Other Contact'},
  ]

  const nameCell = (props) => {
    const { value, data, context } = props;
    return (
      <ContactForm
        item={data}
        OpenModalComponent={({ onClick }) => (
          <span
            className="text-bblue font-medium underline cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            {value}
          </span>
        )}
      />
    )
  };

  const deleteCell = (props) => {
    const { data } = props;
    return (
      <DeleteContactConfirmation
        contactId={data.id}
        afterDelete={contactReload}
        OpenModalComponent={({onClick}) => (
          <button
            className="text-bblue font-medium underline"
            onClick={(e) => {
              e.stopPropagation();
              onClick();
          }}>
            <TrashIcon width={16} height={16} />
          </button>
      )}></DeleteContactConfirmation>
    )
  }

  const contactCols = [
    {field: 'name', headerName: 'Name', cellRenderer: nameCell, flex: '1'},
    {field: 'kind', headerName: 'Type', cellRenderer: (props) => {
      const { value } = props;
      return kindOptions.find(x => x.value === value)?.label
    }},
    {field: 'title', headerName: 'Title'},
    {field: 'email', headerName: 'E-mail Address'},
    {field: 'phone', headerName: 'Phone Number'},
    {field: 'delete', headerName: '', cellRenderer: deleteCell, sortable: false},
  ]

  return (
    <>
      <AgGridReact
        rowData={contactData?.contacts || []}
        columnDefs={contactCols}
        autoSizeStrategy={{type: "fitCellContents"}}
      />
    </>
  )
}