import React, { useState, useEffect, useMemo } from "react"
import { ResponsiveBarCanvas } from '@nivo/bar'
import { useQuery } from "urql"
import { exportCSV } from "../../paul/PaulChartUtils"
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import { Select, Label, TextInput } from "flowbite-react"
import { ILS_PERFORMANCE_METRICS, ILS_METRICS_PROPERTIES } from "../../queries/IlsPerformanceMatricsQueries"
import IlsLeads from "./IlsLeads"
import IlsLeadsTable from "./IlsLeadsTable"

function PerformanceChart({ title, content, data, keyName }) {
  return (
    <div className="mt-8 rounded-md border">
      <div className="flex items-center justify-between p-4">
        <div>
          <p className="mb-1 text-xl font-semibold">{title}</p>
          <p className="text-xs font-medium text-gray-500">{content}</p>
        </div>
      </div>
      <div className="my-2 border-t border-gray-200 opacity-50"></div>
      <div className="h-80">
        {data.length > 0 ? (
          <ResponsiveBarCanvas
            data={data}
            keys={[keyName]} // Single key for each chart (impressions, views, etc.)
            indexBy="timeframe" // Using timeframe as x-axis
            margin={{ top: 30, right: 20, bottom: 50, left: 80 }}
            padding={0.3}
            // Fixed color for all bars
            colors="#006699"
            axisBottom={{
              legend: "Month",
              legendPosition: "middle",
              legendOffset: 25,
              tickRotation: 0
            }}
            axisLeft={{
              legend: title,
              legendPosition: "middle",
              legendOffset: -60
            }}
            enableGridX={false}
            enableGridY={true}
          />
        ) : (
          <p className="flex justify-center items-center h-full text-gray-500">
            No data available
          </p>
        )}
      </div>
    </div>
  )
}

export default function PerformanceDashboard({ source }) {
  const [impressionsData, setImpressionsData] = useState([])
  const [viewsData, setViewsData] = useState([])
  const [leadsData, setLeadsData] = useState([])
  const [contactRateData, setContactRateData] = useState([])
  const [exportData, setExportData] = useState([])
  const [YTDData, setYTDData] = useState([])
  const [selectedProperty, setSelectedProperty] = useState(null)
  const [queryVariables, setQueryVariables] = useState({ ilsSource: source })

  const maxTimeframe = useMemo(() => new Date().toISOString().slice(0, 7), [])
  const minTimeframe = useMemo(
    () => new Date(new Date().setMonth(new Date().getMonth() - 11)).toISOString().slice(0, 7),
    []
  )
  const [startDate, setStartDate] = useState(minTimeframe)
  const [endDate, setEndDate] = useState(maxTimeframe)

  const charts = [
    { title: "Impressions", content: "Impressions content", data: impressionsData, keyName: "impressions" },
    { title: "Views", content: "Views content", data: viewsData, keyName: "views" },
    { title: "Leads", content: "Leads content", data: leadsData, keyName: "leads" },
    { title: "Contact Rate", content: "Contact Rate content", data: contactRateData, keyName: "contactRate" }
  ]

  const [propertyResponse] = useQuery({
    query: ILS_METRICS_PROPERTIES,
    variables: { ilsSource: source, type: "performance" },
    requestPolicy: "network-only"
  })

  const { data: propertyData, fetching: fetchingProperties, error: errorProperties } = propertyResponse


  const [result] = useQuery({
    query: ILS_PERFORMANCE_METRICS,
    variables: queryVariables,
    requestPolicy: "network-only"
  })

  const { data, fetching, error } = result

  useEffect(() => {
    if (data) {
      // Separate the data into impressions, views, and leads
      const impressions = data.ilsPerformanceMetrics.map((item) => ({
        timeframe: item.timeframe,
        impressions: item.impressions
      }))
      const views = data.ilsPerformanceMetrics.map((item) => ({
        timeframe: item.timeframe,
        views: item.views
      }))
      const leads = data.ilsPerformanceMetrics.map((item) => ({
        timeframe: item.timeframe,
        leads: item.leads
      }))
      const contactRates = data.ilsPerformanceMetrics.map((item) => ({
        timeframe: item.timeframe,
        contactRate: item.contactRate
      }))

      const ytdSummary = data.ilsPerformanceMetrics.reduce(
        (acc, item) => {
          acc.totalImpressions += item.impressions || 0
          acc.totalViews += item.views || 0
          acc.totalLeads += item.leads || 0
          acc.totalContactRate += item.contactRate || 0
          return acc
        },
        { totalImpressions: 0, totalViews: 0, totalLeads: 0, totalContactRate: 0 }
      )

      setImpressionsData(impressions)
      setViewsData(views)
      setLeadsData(leads)
      setContactRateData(contactRates)
      setExportData(data?.ilsPerformanceMetrics)
      setYTDData(ytdSummary)
    }
  }, [data, source])

  const handleSearch = () => {
    if (startDate && endDate && startDate > endDate) {
      alert("Please select both start and end dates. start date should be less than end date")
      return
    }

    setQueryVariables({
      ilsSource: source,
      startDate,
      endDate,
      selectedProperty: selectedProperty ? parseInt(selectedProperty, 10) : null
    })
  }

  const handlePropertyChange = (event) => {
    setSelectedProperty(event.target.value)
  }

  if (fetchingProperties) return <div>Loading properties...</div>
  if (errorProperties) return <div>Error loading properties: {errorProperties.message}</div>

  if (fetching) return <div>Loading leads...</div>
  if (error) return <div>Error loading leads: {error.message}</div>

  return (
    <div>
      <div className="flex items-center space-x-4 my-4">
        <div className="flex items-center">
          <Select value={selectedProperty || ""} className="min-w-[9rem] font-medium" onChange={handlePropertyChange} >
            <option value="" disabled>Select a property</option>
            {propertyData?.ilsMetricsProperties.map((property) => (
              <option key={property.propertyId} value={property.propertyId}>
                {property.propertyName}
              </option>
            ))}
          </Select>
        </div>
        {/* Start Date */}
        <div className="flex items-center">
          <Label className="m-0 mr-2">Start Month</Label>
          <TextInput
            type="month"
            id="startDate"
            value={startDate}
            min={minTimeframe}
            max={maxTimeframe}
            onChange={(e) => setStartDate(e.target.value)}
            className="min-w-[9rem] font-medium"
          />
        </div>

        {/* End Date */}
        <div className="flex items-center">
          <Label className="m-0 mr-2">End Month</Label>
          <TextInput
            type="month"
            id="endDate"
            value={endDate}
            min={minTimeframe}
            max={maxTimeframe}
            onChange={(e) => setEndDate(e.target.value)}
            className="min-w-[9rem] font-medium"
          />
        </div>

        {/* Search Button */}
        <button
          onClick={handleSearch}
          className="bg-bblue text-white px-4 py-2 rounded"
          disabled={ !selectedProperty && !startDate && !endDate }
          className={`px-4 py-2 rounded ${
              !selectedProperty && !startDate && !endDate
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-blue-600 text-white"
            }`}
        >
          Search
        </button>

        {/* Download Button */}
        <button
          onClick={() => exportCSV(exportData, `${source} Data Export`)}
          className="text-bblue flex items-center font-medium"
        >
          Download CSV
          <ArrowDownTrayIcon className="ml-2 h-5 w-5" />
        </button>
      </div>

      <div className="mt-8">
        <IlsLeads selectedTab={source} ytdSummary={YTDData} />
      </div>

      {charts.map((chart, index) => (
        <PerformanceChart
          key={index}
          title={chart.title}
          content={chart.content}
          data={chart.data}
          keyName={chart.keyName}
        />
      ))}

      <div className="mt-8">
        <IlsLeadsTable selectedTab={source} queryVariables={queryVariables} />
      </div>
    </div>
  )
}
