import React, { useContext, useEffect, useMemo, useState } from "react"
import BreadCrumbs from "../../shared/BreadCrumbs"
import AGGridTable from "../../shared/AGGridTable"
import OfferForm from "./OfferForm"
import { GlobalContext } from "../../context/GlobalContext"
import { OFFERS_QUERY } from "../../queries/OffersMutations";
import { useQuery } from "urql"
// import { OffersContext } from "../../context/OffersContext"
import styled from "styled-components"

const TableWrapper = styled.div`
  .table th {
    white-space: nowrap;
    font-size: 13px;
  }
`

export default function OfferShow({ params }) {
  const unitId = parseInt(JSON.parse(params)["id"])
  const [breadCrumbs, setBreadCrumbs] = useState()
  const { unitData, reloadUnit } = useContext(GlobalContext)

  useEffect(() => {
    if (params) {
      reloadUnit(unitId)
    }
  }, [params])

  useMemo(() => {
    if (unitData) {
      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard" },
        { link: "/portal/properties", title: 'Properties' },
        { link: `/portal/units/${unitData.unit.propertyId}`, title: `Units` },
        { link: null, title: 'Offers' }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [unitData])

  const [{ data: offersData }] = useQuery({
    query: OFFERS_QUERY,
    variables: { unitId: unitId },
    pause: false
  })

  const columns = [
    {
      accessor: "description",
      title: "Description",
      type: "text"
    },
    {
      accessor: "term",
      title: "Term",
      type: "text"
    },
    {
      accessor: "effectiveRent",
      title: "Effective Rent",
      type: "currency"
    },
    {
      accessor: "startDate",
      title: "Start Date",
      type: "date"
    },
    {
      accessor: "endDate",
      title: "End Date",
      type: "date"
    },
  ]

  const descriptionCell = (props) => {
    const { value, data, context } = props;
    return (
      <OfferForm
        item={data}
        OpenModalComponent={({ onClick }) => (
          <span
            className="font-medium text-bblue underline cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            {value}
          </span>
        )}
      />
    )
  };

  const customCellRenderers = useMemo(() => ({
    description: descriptionCell,
  }), []);

  return (
    <div className="p-8">
      {offersData && breadCrumbs && (
        <div>
          <div className="mb-4">
            <BreadCrumbs items={breadCrumbs} />
          </div>

          <div className="mb-4 space-x-4">
            <OfferForm item={{unitId: unitId}} OpenModalComponent={({ onClick }) => (
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
                className="text-bblue underline cursor-pointer"
              >
                Add new offer
              </a>
            )}>

            </OfferForm>
          </div>
          <TableWrapper>
            <AGGridTable
              data={offersData?.offers || []}
              customCellRenderers={customCellRenderers}
              headerColumns={columns}
            />
          </TableWrapper>
        </div>
      )}
    </div>
  )
}