import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell
} from 'recharts';

const formatValue = (value, type) => {
  if (value == null || value === '') return '';

  switch(type) {
    case 'currency':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value);

    case 'percentage':
      return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
      }).format(value / 100);

    case 'number':
      return new Intl.NumberFormat('en-US').format(value);

    default:
      return value;
  }
};

const getFormatter = (field, columns = [], config = {}) => {
  // First try getting format from columns
  const column = columns?.find(col => col.field === field);
  if (column?.type) {
    return value => formatValue(value, column.type);
  }

  // Fall back to config formatters if provided
  if (config.formatters?.[field]) {
    return value => formatValue(value, config.formatters[field]);
  }

  // Last resort - no formatting
  return value => value;
};

export const renderBarChart = (data, config, columns = []) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={JSON.parse(data)} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={config.xAxis}
          angle={-45}
          textAnchor="end"
          height={60}
        />
        <YAxis tickFormatter={value => getFormatter(config.bars[0].dataKey, columns, config)(value)} />
        <Tooltip
          formatter={(value, name, props) => {
            const field = props.dataKey;
            return [
              getFormatter(field, columns, config)(value),
              config.bars.find(b => b.dataKey === field)?.name || name
            ];
          }}
        />
        <Legend />
        {config.bars?.map((bar, index) => (
          <Bar
            key={bar.dataKey}
            dataKey={bar.dataKey}
            name={bar.name || bar.dataKey}
            fill={bar.color || `hsl(${index * 60}, 70%, 50%)`}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export const renderLineChart = (data, config, columns = []) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={JSON.parse(data)} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={config.xAxis}
          angle={-45}
          textAnchor="end"
          height={60}
        />
        <YAxis tickFormatter={value => getFormatter(config.lines[0].dataKey, columns, config)(value)} />
        <Tooltip
          formatter={(value, name, props) => {
            const field = props.dataKey;
            return [
              getFormatter(field, columns, config)(value),
              config.lines.find(l => l.dataKey === field)?.name || name
            ];
          }}
        />
        <Legend />
        {config.lines?.map((line, index) => (
          <Line
            key={line.dataKey}
            type="monotone"
            dataKey={line.dataKey}
            name={line.name || line.dataKey}
            stroke={line.color || `hsl(${index * 60}, 70%, 50%)`}
            dot={line.showDots !== false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export const renderPieChart = (data, config, columns = []) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={JSON.parse(data)}
          dataKey={config.value}
          nameKey={config.label}
          cx="50%"
          cy="50%"
          label={({ name, value }) => `${name}: ${getFormatter(config.value, columns, config)(value)}`}
        >
          {JSON.parse(data).map((entry, index) => (
            <Cell
              key={index}
              fill={config.colors?.[index] || `hsl(${index * 360 / JSON.parse(data).length}, 70%, 50%)`}
            />
          ))}
        </Pie>
        <Tooltip
          formatter={(value, name) => [
            getFormatter(config.value, columns, config)(value),
            name
          ]}
        />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export const renderHorizontalBarChart = (data, config, columns = []) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        layout="vertical"
        data={JSON.parse(data)}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" tickFormatter={value => getFormatter(config.bars[0].dataKey, columns, config)(value)} />
        <YAxis dataKey={config.yAxis} type="category" />
        <Tooltip
          formatter={(value, name, props) => {
            const field = props.dataKey;
            return [
              getFormatter(field, columns, config)(value),
              config.bars.find(b => b.dataKey === field)?.name || name
            ];
          }}
        />
        <Legend />
        {config.bars?.map((bar, index) => (
          <Bar
            key={bar.dataKey}
            dataKey={bar.dataKey}
            name={bar.name || bar.dataKey}
            fill={bar.color || `hsl(${index * 60}, 70%, 50%)`}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export const renderStackedBarChart = (data, config, columns = []) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={JSON.parse(data)} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={config.xAxis}
          angle={-45}
          textAnchor="end"
          height={60}
        />
        <YAxis tickFormatter={value => getFormatter(config.bars[0].dataKey, columns, config)(value)} />
        <Tooltip
          formatter={(value, name, props) => {
            const field = props.dataKey;
            return [
              getFormatter(field, columns, config)(value),
              config.bars.find(b => b.dataKey === field)?.name || name
            ];
          }}
        />
        <Legend />
        {config.bars?.map((bar, index) => (
          <Bar
            key={bar.dataKey}
            dataKey={bar.dataKey}
            name={bar.name || bar.dataKey}
            fill={bar.color || `hsl(${index * 60}, 70%, 50%)`}
            stackId="stack1"
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export { formatValue };