import { Accordion } from "flowbite-react"
import React, { useEffect } from "react"
import { useQuery } from "urql"

import Loading from "../../shared/Loading"

import AudienceSection from "./AudienceSection"
import DestinationsSection from "./DestinationsSection"
import OrganicPhrasesSection from "./OrganicPhrasesSection"
import PaidPhrasesSection from "./PaidPhrasesSection"
import RankSection from "./RankSection"
import RelatedSection from "./RelatedSection"
import SocialMediaSection from "./SocialMediaSection"
import SubfolderSection from "./SubfolderSection"
import { useTrackComponentView } from '../../IntercomTracker';

const PROPERTIES_QUERY = `
  query {
    properties {
      id
      name
      city
      state {
        name
        abbreviation
      }
      semrushData(order: "id DESC") {
        id
        keyword
        semrushType
        data
      }
    }
  }
`

export default function SEOAudits() {
  const [{ data: propertiesData, fetching }, propertyReload] = useQuery({
    query: PROPERTIES_QUERY,
    requestPolicy: "network-only"
  })

  const trackPropertyView = (property) => {
    if (window.Intercom) {
      window.Intercom('trackEvent', 'seo_audit_property_viewed', {
        property_name: property.name,
        property_location: `${property.city}, ${property.state?.abbreviation}`,
        has_data: property.semrushData.length > 0,
        location: window.location.pathname,
        timestamp: new Date().toISOString()
      });
    }
  };

  useTrackComponentView('seo_audit');

  return (
    <div className="p-8">
      <h1 className="text-xl font-semibold">Who's Searching What</h1>
      <p className="text-lg font-medium">See how people are searching for apartments in your geographies.</p>
      <p className="mt-2 font-normal">
        For each property, we audit the top results and relevant data for the following queries, which are consistently
        the top searches:
      </p>
      <div className="w-1/2">
        <ul className="ml-4 mt-4 grid list-disc grid-cols-2 gap-2 font-normal text-bgold font-bold">
          <li>"[city] [state] apartments"</li>
          <li>"1 bedroom apartments [city] [state]"</li>
          <li>"1 bdr apartments [city] [state]"</li>
          <li>"studio apartments[city] [state]"</li>
          <li>"1 bedroom apartments in [city] [state]"</li>
          <li>"2 bedroom apartments in [city] [state]"</li>
          <li>"pet friendly apartments in [city] [state]"</li>
          <li>"luxury apartments in [city] [state]"</li>
        </ul>
      </div>
      <p className="mt-4 font-normal">
        If any of these don't show up in the first section for the property, it means the search volume was too low for
        us to have data, so they're not queries worth targeting.
      </p>
      <div className="mt-8 flex justify-center">
        {propertiesData ? (
          <Accordion collapseAll className="w-full">
            {propertiesData.properties.map((property, index) => (
              <Accordion.Panel key={index}>
                <Accordion.Title
                  onClickCapture={(e) => {
                    trackPropertyView(property);
                  }}
                >
                  {property.name}
                </Accordion.Title>
                <Accordion.Content>
                  {property.semrushData.length > 0 ? (
                    <>
                      <OrganicPhrasesSection property={property} />
                      <PaidPhrasesSection property={property} />
                      <SubfolderSection property={property} />
                      <RelatedSection property={property} />
                      <DestinationsSection property={property} />
                      <RankSection property={property} />
                      <AudienceSection property={property} />
                      <SocialMediaSection property={property} />
                    </>
                  ) : (
                    <p className="mb-2 text-base font-semibold text-grey">
                      Not enough Google Search volume to show data. We recommend not targeting SEO/PPCs campaigns
                      specifically for {property.city} {property.state?.abbreviation} {property.state?.name} and instead pursuing more local or targeted social campaigns.
                    </p>
                  )}
                </Accordion.Content>
              </Accordion.Panel>
            ))}
          </Accordion>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  )
}
