export const ILS_PERFORMANCE_METRICS = `
  query IlsPerformanceMetrics($ilsSource: String!, $startDate: String, $endDate: String, $selectedProperty: Int) {
    ilsPerformanceMetrics(ilsSource: $ilsSource, startDate: $startDate, endDate: $endDate, selectedProperty: $selectedProperty) {
      timeframe
      impressions
      views
      leads
      contactRate
    }
  }
`

export const ILS_LEADS_QUERY = `
  query IlsLeads($ilsSource: String!, $startDate: String, $endDate: String, $selectedProperty: Int) {
    ilsLeads(ilsSource: $ilsSource, startDate: $startDate, endDate: $endDate, selectedProperty: $selectedProperty) {
      id
      propertyId
      propertyName
      ilsSource
      leadDate
      name
      email
      phone
      message
      leadType
    }
  }
`

export const ILS_METRICS_PROPERTIES = `
  query IlsMetricsProperties($type: String!, $ilsSource: String!) {
    ilsMetricsProperties(type: $type, ilsSource: $ilsSource) {
      propertyId
      propertyName
    }
  }
`
