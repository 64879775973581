export const COMPANIES_QUERY = `
  query {
    companies {
      id
      about
    }
  }
`

export const CURRENT_COMPANY_QUERY = `
  query {
    currentUser {
      currentCompany {
        about
        website
        officeAddress
        separateBrynsonFeeIlsSpend
        contract
        portfolio
        communicationPreference
        managementSoftwares
        ils
        additionalIls
        brynsonOnboarding
      }
    }
  }
`

export const UPDATE_CURRENT_COMPANY_MUTATION = `
  mutation UpdateCurrentCompanyMutation($about:String, $website:String, $contract:JSON, $portfolio:JSON, $communicationPreference:JSON, $managementSoftwares:JSON, $ils:JSON, $additionalIls:JSON, $brynsonOnboarding:JSON, $officeAddress:String) {
    updateCurrentCompanyMutation(about:$about, website:$website, contract:$contract, portfolio:$portfolio, communicationPreference:$communicationPreference, managementSoftwares:$managementSoftwares, ils:$ils, brynsonOnboarding:$brynsonOnboarding, additionalIls:$additionalIls, officeAddress:$officeAddress) {
      result
    }
  }
`
