export const OFFERS_QUERY = `
  query Offers($unitId:ID!){
    offers(unitId:$unitId) {
      id
      description
      term
      startDate
      endDate
      effectiveRent
      unitId
    }
  }
`


export const OFFER_QUERY = `
  query Offer($id:ID!){
    offer(id:$id) {
      id
      description
      term
      startDate
      endDate
      effectiveRent
      unitId
    }
  }
`

export const CREATE_OFFER_MUTATION = `
  mutation CreateOfferMutation($unitId: Int!, $description: String!, $startDate: String, $endDate: String, $effectiveRent: Float) {
    createOfferMutation(
      unitId: $unitId
      description: $description 
      startDate: $startDate
      endDate: $endDate
      effectiveRent: $effectiveRent
    ) {
      id
      description
      unitId
      startDate
      endDate
      effectiveRent
    }
  }
`

export const UPDATE_OFFER_MUTATION = `
  mutation UpdateOfferMutation($id: ID!, $description: String, $unitId: Int!, $startDate: String, $endDate: String, $effectiveRent: Float) {
    updateOfferMutation(
      id: $id
      description: $description
      unitId: $unitId
      startDate: $startDate
      endDate: $endDate
      effectiveRent: $effectiveRent
    ) {
      id
      description
      unitId
      startDate
      endDate
      effectiveRent
    }
  }
`

export const DELETE_OFFER_MUTATION = `
  mutation DeleteOfferMutation($id: ID!) {
    deleteOfferMutation(id: $id) {
      result
      errors
    }
  }
`
