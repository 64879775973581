import React, { createContext } from "react";
import { useMutation } from "urql";
import { CREATE_OFFER_MUTATION, UPDATE_OFFER_MUTATION, DELETE_OFFER_MUTATION } from "../queries/OffersMutations";

export const OffersContext = createContext(null);

const OffersProvider = ({ children, ...props }) => {
  const [, createOffer] = useMutation(CREATE_OFFER_MUTATION)
  const [, updateOffer] = useMutation(UPDATE_OFFER_MUTATION)
  const [, deleteOffer] = useMutation(DELETE_OFFER_MUTATION)  

  return(
    <OffersContext.Provider value={{createOffer, updateOffer, deleteOffer}}>
      {children}
    </OffersContext.Provider>
  );
}

export default OffersProvider;
