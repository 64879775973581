import React, { useContext, useEffect, useState, useMemo } from "react"
import Selectbox from "../../shared/Selectbox"
import { GlobalContext } from "../../context/GlobalContext"
const apartmentscom = require("../../../../../public/ils/apartmentscomlogo.png");
const apartmentlist = require("../../../../../public/ils/apartment-list-logo.png");
const zillow = require("../../../../../public/ils/Zillow_logo.png");
const rentcom = require("../../../../../public/ils/rent-com-logo.png");
const zumper = require("../../../../../public/ils/zumper-logo.png");
const rentable = require("../../../../../public/ils/rentable-logo@2x.jpg");
const realtor = require("../../../../../public/ils/realtor-logo.png");
const rentsocial = require("../../../../../public/ils/rent-social-logo.png");
const maxleases = require("../../../../../public/ils/max-leases-logo.png");


const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const currency = value => currencyFormat.format(value)

export default function DetailedTab() {
  const { propertiesDirectoryData: data, propertiesDirectoryReload: reexecuteQuery, currentUserData } = useContext(GlobalContext)
  const [selectedPropertyDirectory, setSelectedPropertyDirectory] = useState()
  const company = useMemo(() => currentUserData?.currentUser?.currentCompany, [currentUserData])

  const propertyDirectories = useMemo(() => {
    return data?.propertyDirectories || []
  }, [data])

  const sortedPropertyDirectories = useMemo(() =>
    propertyDirectories.sort((a, b) => {
      const dateA = new Date(`${a.month}-01-${a.year}`);
      const dateB = new Date(`${b.month}-01-${b.year}`);
      return dateB - dateA;
    }),
  [propertyDirectories]);


  useEffect(() => {
    if (sortedPropertyDirectories.length > 0) {
      setSelectedPropertyDirectory(sortedPropertyDirectories[0])
    }
  }, [sortedPropertyDirectories]);

  if (!data?.propertyDirectories) {
    return <div>Loading...</div>
  }

  console.log('data')
  console.log(data)

  return (
    <div className="bg-white p-4 shadow">
      <div className="flex flex-row mb-8 justify-between">
        <div className="w-64 mr-8">
          <Selectbox
            options={sortedPropertyDirectories.map(i => ({ value: i, label: `${i.month}-${i.year}` }))}
            onChange={(selectedOption) => {
              setSelectedPropertyDirectory(selectedOption)
            }}
            value={selectedPropertyDirectory ? { value: selectedPropertyDirectory, label: `${selectedPropertyDirectory.month}-${selectedPropertyDirectory.year}` } : null}
            id="property-directory-historic-property-directory"
          />
        </div>
        <div className="flex flex-row items-center justify-center">
          <h4 className="text-bblue font-bold text-2xl mr-2">Total Properties:</h4>
          <h5 className="text-bgold text-3xl font-semibold">{selectedPropertyDirectory?.propertyDirectoryInfo?.length}</h5>
        </div>
        <div className="block w-64">
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4">


        {(selectedPropertyDirectory?.apartmentListTotal > 0 || selectedPropertyDirectory?.apartmentListTotal < 0) &&
          <div className="flex flex-col items-center justify-center p-8">
            <div className="h-[100px] flex items-end">
              <img src={apartmentlist} width="180px" className="mb-4 object-contain" alt="Apartmentlist" />
            </div>
            <h4 className="text-bblue font-bold text-2xl mb-4 text-center">Apartmentlist Platform Fee Total</h4>
            <h5 className="text-bgold text-4xl font-semibold">{currency(selectedPropertyDirectory.apartmentListTotal)}</h5>
          </div>
        }

        {(selectedPropertyDirectory?.apartmentListPplFee > 0 || selectedPropertyDirectory?.apartmentListPplFee < 0) &&
          <div className="flex flex-col items-center justify-center p-8">
            <div className="h-[100px] flex items-end">
              <img src={apartmentlist} width="180px" className="mb-4 object-contain" alt="Apartmentlist" />
            </div>
            <h4 className="text-bblue font-bold text-2xl mb-4 text-center">Apartmentlist PPL Fee</h4>
            <h5 className="text-bgold text-4xl font-semibold">{currency(selectedPropertyDirectory.apartmentListPplFee)}</h5>
          </div>
        }

        {(selectedPropertyDirectory?.costarTotal > 0 || selectedPropertyDirectory?.costarTotal < 0) &&
          <div className="flex flex-col items-center justify-center p-8">
            <div className="h-[100px] flex items-end">
              <img src={apartmentscom} width="180px" className="mb-4 object-contain" alt="Apts.com" />
            </div>
            <h4 className="text-bblue font-bold text-2xl mb-4">CoStar Total</h4>
            <h5 className="text-bgold text-4xl font-semibold">{currency(selectedPropertyDirectory.costarTotal)}</h5>
          </div>
        }
        {selectedPropertyDirectory?.realtorTotal > 0 &&
          <div className="flex flex-col items-center justify-center p-8">
            <div className="h-[100px] flex items-end">
              <img src={realtor} width="90px" className="mb-4 object-contain" alt="Apartmentlist" />
            </div>
            <h4 className="text-bblue font-bold text-2xl mb-4 text-center">Realtor Total</h4>
            <h5 className="text-bgold text-4xl font-semibold">{currency(selectedPropertyDirectory.realtorTotal)}</h5>
          </div>
        }

        {(selectedPropertyDirectory?.rentComTotal > 0 || selectedPropertyDirectory?.rentComTotal < 0) &&
          <div className="flex flex-col items-center justify-center p-8">
            <div className="h-[100px] flex items-end">
              <img src={rentcom} width="90px" className="mb-4 object-contain rounded" alt="Rent." />
            </div>
            <h4 className="text-bblue font-bold text-2xl mb-4 text-center">Rent.com Total</h4>
            <h5 className="text-bgold text-4xl font-semibold">{currency(selectedPropertyDirectory.rentComTotal)}</h5>
          </div>
        }
        {selectedPropertyDirectory?.maxLeasesTotal > 0 &&
          <div className="flex flex-col items-center justify-center p-8">
            <div className="h-[100px] flex items-end">
              <img src={maxleases} width="140px" className="mb-4 object-contain" alt="Apartmentlist" />
            </div>
            <h4 className="text-bblue font-bold text-2xl mb-4 text-center">Max Leases Total</h4>
            <h5 className="text-bgold text-4xl font-semibold">{currency(selectedPropertyDirectory.maxLeasesTotal)}</h5>
          </div>
        }
        {selectedPropertyDirectory?.rentableTotal > 0 &&
          <div className="flex flex-col items-center justify-center p-8">
            <div className="h-[100px] flex items-end">
              <img src={rentable} width="180px" className="mb-4 object-contain" alt="Apartmentlist" />
            </div>
            <h4 className="text-bblue font-bold text-2xl mb-4 text-center">Rentable Total</h4>
            <h5 className="text-bgold text-4xl font-semibold">{currency(selectedPropertyDirectory.rentableTotal)}</h5>
          </div>
        }

        {selectedPropertyDirectory?.rentSocialTotal > 0 &&
          <div className="flex flex-col items-center justify-center p-8">
            <div className="h-[100px] flex items-end">
              <img src={rentsocial} width="140px" className="mb-4 object-contain" alt="Apartmentlist" />
            </div>
            <h4 className="text-bblue font-bold text-2xl mb-4 text-center">RentSocial Total</h4>
            <h5 className="text-bgold text-4xl font-semibold">{currency(selectedPropertyDirectory.rentSocialTotal)}</h5>
          </div>
        }

        {(selectedPropertyDirectory?.zillowTotal > 0 || selectedPropertyDirectory?.zillowTotal < 0) &&
          <div className="flex flex-col items-center justify-center p-8">
            <div className="h-[100px] flex items-end">
              <img src={zillow} width="130px" className="mb-4 object-contain" alt="Zillow" />
            </div>
            <h4 className="text-bblue font-bold text-2xl mb-4 text-center">Zillow Total</h4>
            <h5 className="text-bgold text-4xl font-semibold">{currency(selectedPropertyDirectory.zillowTotal)}</h5>
          </div>
        }

        {(selectedPropertyDirectory?.zillowPplFee > 0 || selectedPropertyDirectory?.zillowPplFee < 0) &&
          <div className="flex flex-col items-center justify-center p-8">
            <div className="h-[100px] flex items-end">
              <img src={zillow} width="130px" className="mb-4 object-contain" alt="Zillow" />
            </div>
            <h4 className="text-bblue font-bold text-2xl mb-4 text-center">Zillow PPL Fee</h4>
            <h5 className="text-bgold text-4xl font-semibold">{currency(selectedPropertyDirectory.zillowPplFee)}</h5>
          </div>
        }

        {(selectedPropertyDirectory?.zumperTotal > 0 || selectedPropertyDirectory?.zumperTotal < 0) &&
          <div className="flex flex-col items-center justify-center p-8">
            <div className="h-[100px] flex items-end">
              <img src={zumper} width="180px" className="mb-4 object-contain" alt="Apartmentlist" />
            </div>
            <h4 className="text-bblue font-bold text-2xl mb-4 text-center">Zumper Total</h4>
            <h5 className="text-bgold text-4xl font-semibold">{currency(selectedPropertyDirectory.zumperTotal)}</h5>
          </div>
        }

      </div>
    </div>
  )
}
