import { ResponsiveLine } from "@nivo/line"
import clsx from "clsx"
import { Label, Select, Table, Tooltip } from "flowbite-react"
import React, {useState} from "react"
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import { UnitCollection } from "./UnitCollection"

export function AverageRentsByUnitType({ properties = [], className = "" }) {
  const [trendFilter, setTrendFilter] = useState("ask")
  const units = UnitCollection.fromProperties(properties)
  const groupedByFloorplan = units.groupByProperty()
  const rents = Object.entries(groupedByFloorplan).map(([, unitCollection]) => unitCollection.toRent())
  return (
    <div className={clsx("overflow-hidden rounded-md border", className)}>
      <div className="flex items-center justify-between p-4">
        <div>
          <p className="mb-1 text-xl font-semibold">Average Rents by Unit Type</p>
          <p className="text-xs font-medium text-gray-500">
            Analyze rent and availability trends among your closest comps with unit-type level data.
          </p>
        </div>
        <div className="flex items-center">
          <Label className="m-0 mr-2">Trend</Label>
          <Select
            onChange={(e) => setTrendFilter(e.target.value)}
            className="min-w-[8rem] font-medium"
          >
            <option value="ask">Ask Rent</option>
            <option value="eff">Effective Rent</option>
          </Select>
        </div>
        {/* <div className="flex items-center space-x-8">
          <Button color="gray" onClick={onExport}>
            <HiDownload className="mr-2 h-4 w-4" />
            Export to CSV
          </Button>
        </div> */}
      </div>
      <AverageRentsByUnitTypeTable rents={rents} trendFilter={trendFilter} />
    </div>
  )
}

export function AverageRentsByUnitTypeTable({ rents = [], trendFilter = "ask" }) {
  function lineData(prices, index) {
    // Return default data if prices are not provided
    if (!prices || prices.length <= 1) {
      return [
        {
          id: "kdata-" + index,
          color: "hsl(220, 70%, 50%)",
          data: [{ x: -1, y: 0.0 }, { x: 0, y: 0.0 }, { x: 1, y: 0.0 }]
        }
      ]
    }

    return [
      {
        id: "kdata-" + index,
        color: "hsl(220, 70%, 50%)",
        data: prices.map((price, priceIndex) => ({
          x: priceIndex,
          y: price
        })).slice(Math.max(prices.length - 20, 0))
      }
    ]
  }

  function v(value) {
    return typeof value === "number" && !isNaN(value) ? value : 0
  }

  function $(value, fixed = 0) {
    const processedValue = typeof value === "number" && !isNaN(value) ? value : parseFloat(value)

    return typeof processedValue === "number" && !isNaN(value) ? `$${fixed > 0 ? processedValue.toFixed(fixed) : processedValue}` : ""
  }

  const comps = rents.filter((rent) => !rent.propertyIsSubject)
  const subject = rents.find((rent) => rent.propertyIsSubject)
  const average = Object.entries(
    comps.reduce(
      (acc, rent) => {
        acc.listings += v(rent.listings)
        acc.available += v(rent.available)
        acc.avgDaysOnMkt += v(rent.avgDaysOnMkt)
        acc.avgAskRent += v(rent.avgAskRent)
        acc.avgEffRent += v(rent.avgEffRent)
        acc.avgSF += v(rent.avgSF)
        acc.avgAskRentPSF += v(rent.avgAskRentPSF)
        acc.avgEffRentPSF += v(rent.avgEffRentPSF)
        return acc
      },
      {
        listings: 0,
        available: 0,
        avgDaysOnMkt: 0,
        avgAskRent: 0,
        avgEffRent: 0,
        avgSF: 0,
        avgAskRentPSF: 0,
        avgEffRentPSF: 0
      }
    )
  ).reduce((acc, [key, val]) => {
    const count = comps.filter((comp) => comp[key]).length
    if (count > 0) {
      acc[key] = ["avgAskRentPSF", "avgEffRentPSF"].includes(key) ? val / count : Math.round(val / count)
    } else {
      acc[key] = 0
    }
    return acc
  }, {})

  return (
    <Table>
      <Table.Head>
        <Table.HeadCell />
        <Table.HeadCell># Listings</Table.HeadCell>
        <Table.HeadCell># Available</Table.HeadCell>
        <Table.HeadCell>Avg Days on Mkt</Table.HeadCell>
        <Table.HeadCell>Avg Ask Rent</Table.HeadCell>
        <Table.HeadCell>Avg Eff Rent</Table.HeadCell>
        <Table.HeadCell>Avg SF</Table.HeadCell>
        <Table.HeadCell>Avg Ask Rent PSF</Table.HeadCell>
        <Table.HeadCell>Avg Eff Rent PSF</Table.HeadCell>
        <Table.HeadCell>Trend</Table.HeadCell>
      </Table.Head>
      <Table.Body className="border-t text-sm">
        {rents.map((rent, index) => (
          <Table.Row
            key={index}
            className={clsx(
              "border-b hover:bg-blue-100",
              rent.current ? "bg-blue-50 font-semibold text-blue-800" : "font-medium text-gray-800"
            )}>
            <Table.Cell
              title={rent.property}
              className="max-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap p-0 py-2 pl-4">
              {rent.property}
            </Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{rent.listings}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{rent.available}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{rent.avgDaysOnMkt}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{$(rent.avgAskRent)}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{$(rent.avgEffRent)}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{rent.avgSF}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{$(rent.avgAskRentPSF, 2)}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{$(rent.avgEffRentPSF, 2)}</Table.Cell>
            <Table.Cell className={clsx("p-0 text-center align-middle flex items-center", rent.askTrend >= 0 ? "text-green-500" : "text-red-500")}>
              <div style={{ height: "40px", width: "80px" }}>
                <ResponsiveLine
                  data={lineData(trendFilter === "eff" ? rent.askEffectiveRents : rent.askRents, index)}
                  enableGridX={false}
                  enableGridY={false}
                  margin={{ top: 1, right: 1, bottom: 1, left: 1 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: "auto",
                    max: "auto",
                    stacked: true,
                    reverse: false
                  }}
                  axisBottom={null}
                  axisLeft={null}
                  colors={{ scheme: "nivo" }}
                  enablePoints={false}
                  enableArea={true}
                  useMesh={false}
                />
              </div>
              {trendFilter === "eff"
                ? `${rent.askEffectiveTrend >= 0 ? `+${rent.askEffectiveTrend}` : `${rent.askEffectiveTrend}`}%`
                : `${rent.askTrend >= 0 ? `+${rent.askTrend}` : `${rent.askTrend}`}%`
              }
            </Table.Cell>
          </Table.Row>
        ))}
        <Table.Row className="border-t bg-gray-50 font-semibold text-gray-800">
          <Table.Cell className="flex items-center p-0 py-1.5 pl-4">
            Comp Average
            <Tooltip className="normal-case" content={`Does not include ${subject?.property}`} placeholder="bottom">
              <InformationCircleIcon className="ml-1 mt-0.5 h-3 w-3 text-gray-700" />
            </Tooltip>
          </Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{average.listings}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{average.available}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{average.avgDaysOnMkt}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{$(average.avgAskRent)}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{$(average.avgEffRent)}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{average.avgSF}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{$(average.avgAskRentPSF, 2)}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{$(average.avgEffRentPSF, 2)}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle"></Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}
