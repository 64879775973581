import React, { useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import { useMutation, useQuery } from 'urql';
import { GlobalContext } from "../../context/GlobalContext";
import PaulFormattedMessage from '../../paul/PaulFormattedMessage';
import SavedPaulChart from '../../paul/SavedPaulChart';
import BrynsonTabs from "../../shared/BrynsonTabs";
import { v4 as uuidv4 } from 'uuid';
import { useTrackComponentView } from '../../IntercomTracker';

const SAVE_CHART_MUTATION = `
  mutation SavePaulChartMutation(
    $sql: String!,
    $chartType: String!,
    $title: String!,
    $config: JSON,
    $columns: JSON,
    $data: JSON
  ) {
    savePaulChartMutation(
      sql: $sql,
      chartType: $chartType,
      title: $title,
      config: $config,
      columns: $columns,
      data: $data
    ) {
      result
      errors
    }
  }
`;

const PROPERTIES_NAMES_QUERY = `
  query {
    properties {
      name
    }
  }
`;

const MessageTypes = {
  TEXT: 'text',
  TABLE: 'table',
  THINKING: 'thinking',
  ERROR: 'error',
  VISUALIZATION: 'visualization',
  REPORT: 'report'
};

const Senders = {
  BOT: 'bot',
  USER: 'user'
};

const DATA_CATEGORIES = [
  {
    title: "ILS Management™",
    examples: [
      "For each property, how many leads am I getting from each ILS?",
      "What was the ROI on my spend on each ILS?",
      "What Apartments.com packages are we listed on, and how many properties are on each?"
    ]
  },
  {
    title: "PMS Data",
    examples: [
      "What was my lead to move in conversion for {property}?",
      "What was my lead to application conversion for {property}?",
      "What is my occupancy and future projected occupancy for my properties?",
    ],
    requiresSetup: true
  },
  {
    title: "SEO Data",
    examples: [
      "What are the top search keywords for {property}?",
      "What is the average CPC for keywords related to {property}?",
      "For {property}, what are the top search results for studio apartments",
      "For {property}, what are the top search results for 1 bedroom apartments?",
      "What's the search volume and cost per click for 1-bedroom apartment searches for {property}"
    ]
  }
];

const ERROR_MESSAGES = {
  NETWORK: 'Unable to connect to the server. Please check your internet connection.',
  GENERAL: 'Sorry, there was an error processing your request.',
  SAVE_ERROR: 'Sorry, there was an error saving your chart.',
  PARSE_ERROR: 'Sorry, there was an error processing the response.',
  INVALID_DATA: 'I apologize, but I received invalid data from the database. Please try your question again.',
  MALFORMED_DATA: 'I apologize, but I received malformed data from the database. Please try your question again.'
};

const Paul = ({ savedCharts }) => {
  const { currentUserData } = useContext(GlobalContext);
  const [{ data: propertiesNamesData }] = useQuery({ query: PROPERTIES_NAMES_QUERY });
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const textareaRef = useRef(null);

  const [, savePaulChart] = useMutation(SAVE_CHART_MUTATION);

  const [sessionId] = useState(() => {
    const stored = localStorage.getItem('paul_session_id');
    if (stored) return stored;
    const newId = uuidv4();
    localStorage.setItem('paul_session_id', newId);
    return newId;
  });

  const userInfo = useMemo(() => ({
    firstName: currentUserData?.currentUser?.name?.split(' ')[0],
    about: currentUserData?.currentUser?.currentCompany?.about,
    userAvatar: currentUserData?.currentUser?.photoUrl
  }), [currentUserData]);

  const scrollToBottom = useCallback((smooth = true) => {
    if (messagesEndRef.current) {
      const container = messagesContainerRef.current;
      const scrollOptions = {
        behavior: smooth ? 'smooth' : 'auto',
        block: 'end',
      };

      try {
        messagesEndRef.current.scrollIntoView(scrollOptions);
      } catch (error) {
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      }
    }
  }, []);

  const handleScroll = useCallback(() => {
    const container = messagesContainerRef.current;
    if (container) {
      const isScrolledUp =
        container.scrollHeight - container.scrollTop > container.clientHeight + 100;
      setShowScrollButton(isScrolledUp);
    }
  }, []);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const resizeObserver = new ResizeObserver(() => {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    });

    resizeObserver.observe(textarea);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (inputMessage.trim() && !isLoading) {
        handleSubmit(e);
      }
    }
  }, [inputMessage, isLoading, handleSubmit]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      const isScrolledToBottom =
        container.scrollHeight - container.scrollTop <= container.clientHeight + 100;

      if (isScrolledToBottom) {
        scrollToBottom(true);
      }
    }
  }, [messages, scrollToBottom]);

  useEffect(() => {
    scrollToBottom(false);
  }, [scrollToBottom]);

  useEffect(() => {
    const clearSession = () => {
      localStorage.removeItem('paul_session_id');
    };
    const timeoutId = setTimeout(clearSession, 24 * 60 * 60 * 1000);
    return () => {
      clearTimeout(timeoutId);
      clearSession();
    };
  }, []);

  const handleError = useCallback(async (error, customMessage, errorData = {}) => {
    console.error('Error:', error);
    const message = {
      text: customMessage || ERROR_MESSAGES.GENERAL,
      sender: Senders.BOT,
      type: MessageTypes.ERROR,
      ...errorData
    };
    setMessages(prev => [...prev, message]);
  }, []);

  const handleSaveChart = useCallback(async (chartData) => {
    const title = messages
      .filter(msg => msg.sender === Senders.USER)
      .slice(-1)[0]?.text || 'Untitled Chart';

    setIsLoading(true);
    try {
      const { sql, chart_type: chartType, data, columns, config } = chartData;

      const result = await savePaulChart({
        sql,
        chartType,
        title,
        config: JSON.stringify(config || {}),
        columns: JSON.stringify(columns || []),
        data: JSON.stringify(data)
      });

      if (result.error) {
        await handleError(result.error, ERROR_MESSAGES.SAVE_ERROR);
        return;
      }

      if (result.data?.savePaulChartMutation.result === 'success') {
        setMessages(prev => [...prev, {
          text: 'Great, your chart has been saved to the other tab! If you refresh the page, you can view it there.',
          sender: Senders.BOT,
          type: MessageTypes.TEXT
        }]);
      }
    } catch (error) {
      await handleError(error, ERROR_MESSAGES.SAVE_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [savePaulChart, messages, handleError]);

  const handleStreamEvent = useCallback(async (data) => {
    try {
      setMessages(prev => {
        const filtered = data.type === MessageTypes.THINKING
          ? prev
          : prev.filter(msg => msg.type !== MessageTypes.THINKING);

        if (data.type === MessageTypes.THINKING) {
          const withoutLastThinking = prev.filter((msg, index) => {
            if (msg.type === MessageTypes.THINKING) {
              return index !== prev.length - 1;
            }
            return true;
          });
          return [...withoutLastThinking, {
            text: data.content,
            sender: Senders.BOT,
            type: MessageTypes.THINKING
          }];
        }

        // Create message with full context
        const newMessage = {
          ...data,
          sender: Senders.BOT,
          text: data.content || data.text || '',
          content: data.content || data.text || '',
          df: data.df,
          sql: data.sql,
          team_sql: data.team_sql,
          visualization: data.visualization,
          chart_type: data.chart_type,
          config: data.config,
          column_formats: data.column_formats,
          table: data.table,
          report: data.report
        };

        return [...filtered, newMessage];
      });
    } catch (error) {
      await handleError(error, ERROR_MESSAGES.PARSE_ERROR);
    }
  }, [handleError]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || isLoading) return;

    const userMessage = {
      text: inputMessage,
      sender: Senders.USER,
      type: MessageTypes.TEXT
    };

    setIsLoading(true);
    setMessages(prev => [
      ...prev,
      userMessage,
      {
        text: "Thinking...",
        sender: Senders.BOT,
        type: MessageTypes.THINKING
      }
    ]);
    setInputMessage('');

    try {
      const token = document.querySelector('meta[name="csrf-token"]').content;

      // Convert messages to the format Claude expects, preserving all context
      const formattedHistory = messages.map(msg => {
        const base = {
          role: msg.sender === Senders.USER ? 'user' : 'assistant',
          content: msg.text,
          type: msg.type
        };

        // For bot messages, preserve all context
        if (msg.sender === Senders.BOT) {
          return {
            ...base,
            // Preserve visualization context
            visualization: msg.visualization,
            chart_type: msg.chart_type,
            config: msg.config,

            // Preserve data context
            df: msg.df,
            sql: msg.sql,
            team_sql: msg.team_sql,

            // Preserve formatting context
            column_formats: msg.column_formats,

            // Add any tables or reports
            table: msg.table,
            report: msg.report
          };
        }

        return base;
      });

      // In handleSubmit
      const response = await fetch('/claude/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        body: JSON.stringify({
          claude: {
            question: inputMessage,
            history: formattedHistory,
            session_id: sessionId
          }
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Set up the reader for streaming
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        // Process any complete messages in buffer
        const lines = buffer.split('\n');
        buffer = lines.pop() || ''; // Keep any incomplete line in buffer

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(5));

              if (data.type === 'complete') {
                setIsLoading(false);
                return;
              }

              await handleStreamEvent(data);
            } catch (err) {
              console.error('Error parsing stream data:', err);
            }
          }
        }
      }

    } catch (error) {
      const errorMessage = error.message === 'Failed to fetch'
        ? ERROR_MESSAGES.NETWORK
        : ERROR_MESSAGES.GENERAL;
      await handleError(error, errorMessage);
      setIsLoading(false);
    }
  }, [inputMessage, messages, sessionId, handleStreamEvent, handleError, isLoading]);
  const handleClearConversation = useCallback(() => {
    setMessages([]);
    localStorage.removeItem('paul_session_id');
    window.location.reload();
  }, []);

  const renderMessage = useCallback((message, index) => {
    const isFirstInChain = index === 0 || messages[index - 1].sender !== message.sender;

    return (
      <PaulFormattedMessage
        key={index}
        message={message}
        userAvatar={userInfo.userAvatar}
        onSaveChart={handleSaveChart}
        showAvatar={isFirstInChain}
      />
    );
  }, [messages, userInfo.userAvatar, handleSaveChart]);

  const welcomeScreen = useMemo(() => (
    <div className="flex justify-center">
      <div className="max-w-[1200px] text-xl text-navy">
        <div>
          Hi {userInfo.firstName}! Ask me a question about your Brynsights™ data, I'm here to help.
          <div className="grid grid-cols-3 gap-4 mt-4">
            {DATA_CATEGORIES.map((category, index) => (
              <div key={index} className="card rounded bg-slate-100 hover:bg-slate-300">
                <div className="card-content">
                  <h5 className="mb-2 text-xl font-semibold text-center">
                    {category.title}
                  </h5>
                  {category.requiresSetup && userInfo.about !== "PMC Demo" && (
                    <p className="font-semibold text-bgold">
                      Contact your client success manager to give Paul access to this data!
                    </p>
                  )}
                  <ul className="list-disc ml-5 mt-2 text-base leading-tight">
                    {category.examples.map((example, idx) => (
                      <li key={idx} className={idx > 0 ? "mt-2" : ""}>
                        {example.replace('{property}', propertiesNamesData?.properties[0]?.name || 'your property')}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ), [userInfo.firstName, userInfo.about, propertiesNamesData]);

  useTrackComponentView('paul_page');

  return (
    <div className="p-8">
      <BrynsonTabs className="mt-4" tabs={[
        { label: "Ask Paul" },
        { label: "Saved Charts" }
      ]}>
        <div className="flex justify-center">
          <div className="w-full min-h-96 h-full flex flex-col pb-24">
            <div>
              <h1 className="font-semibold text-2xl mt-4 text-center flex justify-center">
                Ask Paul
                <span className="text-xs ml-1 text text-bgold">BETA</span>
              </h1>
              <h1 className="font-medium italic text-lg mb-6 mt-2 text-center">
                About your data in Brynsights™
              </h1>
              {messages.length > 0 && (
                <div className="w-full text-right">
                  <button
                    onClick={handleClearConversation}
                    className="ml-4 text-sm text-gray-500 hover:text-gray-700"
                  >
                    Clear Conversation
                  </button>
                </div>
              )}
            </div>

            <div
              ref={messagesContainerRef}
              className="flex-grow overflow-y-auto px-4 pt-4 pb-8 font-medium"
            >
              {messages.length === 0 ? welcomeScreen : messages.map(renderMessage)}
              <div ref={messagesEndRef} />
            </div>

            {showScrollButton && (
              <button
                onClick={() => scrollToBottom(true)}
                className="fixed bottom-24 right-8 bg-blue-500 text-white rounded-full p-3 shadow-lg hover:bg-blue-600 transition-colors"
              >
                <span className="sr-only">Scroll to bottom</span>
                ↓
              </button>
            )}

            <form onSubmit={handleSubmit} className={`p-4 border-t bg-white ${messages.length > 0 ? 'fixed bottom-0 left-[252px] right-0' : ''}`}>
              <div className="flex max-w-[1200px] mx-auto">
                <div className="flex-grow relative">
                  <textarea
                    ref={textareaRef}
                    value={inputMessage}
                    onChange={e => setInputMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Type a question..."
                    disabled={isLoading}
                    rows={1}
                    className="w-full border rounded-l-lg p-2 outline-none focus:ring-0 disabled:bg-gray-100 resize-none overflow-hidden"
                    style={{
                      minHeight: '42px',
                      maxHeight: '200px'
                    }}
                  />
                </div>

                <button
                  type="submit"
                  disabled={isLoading || !inputMessage.trim()}
                  className={`text-white rounded-r-lg p-2 transition-colors ${
                    isLoading || !inputMessage.trim()
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'hover:bg-blue-600'
                  }`}
                  style={{
                    backgroundColor: isLoading || !inputMessage.trim() ? undefined : 'rgb(20, 49, 86)',
                    height: '42px'
                  }}
                >
                  {isLoading ? 'Thinking...' : 'Send'}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="flex justify-center">
          {savedCharts.length > 0 ? (
            <div className="w-full min-h-96 h-full flex flex-col">
              {savedCharts.map((chart) => (
                <div className="mt-6" key={chart.id}>
                  <SavedPaulChart chart={chart} />
                </div>
              ))}
            </div>
          ) : (
            <h2 className="text-lg font-medium italic max-w-[850px] mt-16 text-center">
              You don't have any saved charts yet. Chat with Paul and once he gives you a table or chart you like,
              you can save it here and it will automatically be updated with new data.
            </h2>
          )}
        </div>
      </BrynsonTabs>
    </div>
  );
};

export default Paul;