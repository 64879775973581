import React, { useState } from "react"
import { useMutation } from "urql";
import Modal from "../../../shared/Modal"
import DesktopH5 from "../../../shared/DesktopH5"
import DesktopTitle3 from "../../../shared/DesktopTitle3"
import BrynsonSquareButton from "../../../shared/BrynsonSquareButton"
import { DELETE_CONTACT_MUTATION } from "../../../queries/ContactsMutations";

export default function DeleteConfirmation({contactId, OpenModalComponent, afterDelete}) {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null)
  const [, deleteContact] = useMutation(DELETE_CONTACT_MUTATION)
  return (
    <>
      <OpenModalComponent onClick={() => setOpen(true)}></OpenModalComponent>
      <Modal
        size="small"
        open={open}
        header={
          <>
            <DesktopH5 content="Delete Contact" />
          </>
        }
        footer={
          <>
            <BrynsonSquareButton
              type="link"
              size="medium"
              onClick={() => {
                setShowDeleteProperty(false)
                refreshProperty()
              }}
              label="Go Back"
            />
            <BrynsonSquareButton
              type="warning"
              size="medium"
              loading={loading}
              className={"flex items-center justify-center"}
              onClick={() => {
                setLoading(true)
                deleteContact({id: parseInt(contactId)}).then((result) => {
                  if (result?.error || result?.data?.contact?.errors) {
                    setError(`Error updating offer info: ${result?.error || result?.data?.offer?.errors}`)
                  } else {
                    afterDelete()
                    setOpen(false)
                  }
                  setLoading(false)
                })
              }}
              label="Delete Contact"
            />
          </>
        }>
        {error && <p className="text-red-600 mb-2">{error}</p>}
        <DesktopTitle3 content="Are you sure you want to delete this contact? This action can't be undone." />
      </Modal>
    </>
    
  )
}