import React, { useImperativeHandle, forwardRef, useRef, useState, useEffect, useContext } from "react";
import { useQuery } from "urql";
import { AgGridReact } from "ag-grid-react";
import ClientAccessForm from "./ClientAccessForm"
import DeleteClientAccessConfirmation from "./DeleteClientAccessConfirmation"
import { CLIENT_ACCESSES_QUERY } from "../../../queries/ClientAccessesMutations";
import { GlobalContext } from "../../../context/GlobalContext"
import { TrashIcon } from "@heroicons/react/24/solid"

const ClientAccessGrid = forwardRef((props, ref) => {
  const gridRef = useRef()
  const [data, setData] = useState([])
  const { propertiesData: propertiesData } = useContext(GlobalContext)
  
  const [{ data: clientAccessData }, clientAccessReload] = useQuery({
    query: CLIENT_ACCESSES_QUERY,
		requestPolicy: "network-only",
    pause: false
  })

  useEffect(() => {
    if (clientAccessData?.clientAccesses && propertiesData?.properties) {
      const items = clientAccessData.clientAccesses
      items.forEach(item => {
        item.propertiesToAccess = propertiesData.properties.filter(x => item.propertiesToAccess.includes(x.id)).map(x => x.name)
      });
      setData(items);
    }
  }, [clientAccessData, propertiesData]);

  const fullNameCell = (props) => {
    const { value, data, context } = props;
    return (
      <ClientAccessForm
        item={data}
        OpenModalComponent={({ onClick }) => (
          <span
            className="text-bblue font-medium underline cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            {value}
          </span>
        )}
      />
    )
  };

  const deleteCell = (props) => {
    const { data } = props;
    return (
      <DeleteClientAccessConfirmation
        id={data.id}
        afterDelete={clientAccessReload}
        OpenModalComponent={({onClick}) => (
          <button
            className="text-bblue font-medium underline"
            onClick={(e) => {
              e.stopPropagation();
              onClick();
          }}>
            <TrashIcon width={16} height={16} />
          </button>
      )}></DeleteClientAccessConfirmation>
    )
  }

  useImperativeHandle(ref, () => ({
    getData: () => {
      const data = [];
      gridRef.current.api.forEachNode(node => {
        if (node.data) {
          data.push(node.data);
        }
      });

      return data
    },
  }));
  
  return (
    <AgGridReact
      ref={gridRef}
      defaultColDef={{flex: 1}}
      rowData={data}
      columnDefs={[
        {field: "fullname",headerName: "Full Name", cellRenderer: fullNameCell},
        {field: "complete", headerName: "Complete"},
        {field: "dateAdded", headerName: "Date Added"},
        {field: "title", headerName: "Title"},
        {field: "email", headerName: "E-mail"},
        {field: "region", headerName: "Region/Market"},
        {field: "access", headerName: "Accesses"},
        {field: "propertiesToAccess", headerName: "Properties"},
        {field: 'delete', headerName: '', cellRenderer: deleteCell, sortable: false},
      ]}
      autoSizeStrategy={{type: "fitCellContents"}}
    />
  );
})

export default ClientAccessGrid